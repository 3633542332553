<template>
  <div id="kt_app_footer" class="app-footer">
    <div
      class="app-container container-fluid d-flex flex-column flex-md-row flex-center flex-md-stack py-3"
    >
      <div class="text-dark order-2 order-md-1">
        <span class="text-muted fw-semibold me-1">GreenArt Operators App</span>
        <a
          href="https://www.greenart.co.uk"
          target="_blank"
          class="text-gray-800 text-hover-primary"
          >GreenArt</a
        >
      </div>

      <ul class="menu menu-gray-600 menu-hover-primary fw-semibold order-1">
        <li class="menu-item">
          <a
            href="mailto:support@garbott.co.uk"
            target="_blank"
            class="menu-link px-2"
            >Support</a
          >
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style>
</style>