<template>
  <div :class="{ 'page-loading': getIsLoading }">
    <div v-if="getWrapperIsApp">
      <div class="d-flex flex-column flex-root app-root" id="kt_app_root">
        <div class="app-page flex-column flex-column-fluid" id="kt_app_page">
          <TopMenu v-if="isLoggedIn" />
          <div class="flex-column flex-row-fluid" id="kt_app_wrapper">
            <!-- <LeftMenu v-if="isLoggedIn" /> -->
            <div class="app-main flex-column flex-row-fluid" id="kt_app_main">
              <div class="d-flex flex-column flex-column-fluid">
                <Breadcrumb v-if="isLoggedIn" />
                <div id="kt_app_content" class="app-content flex-column-fluid">
                  <div
                    id="kt_app_content_container"
                    class="app-container container-xxl"
                  >
                    <router-view></router-view>
                  </div>
                </div>
              </div>
              <Footer />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-else>
      <router-view></router-view>
    </div>

    <loading />
  </div>
</template>

<script>
import Loading from "./components/Loading.vue";
import Footer from "./views/partial/Footer.vue";
import TopMenu from "./views/partial/TopMenu.vue";
import LeftMenu from "./views/partial/LeftMenu.vue";
import Breadcrumb from "./views/partial/Breadcrumb.vue";
import { mapGetters, mapActions } from "vuex";
export default {
  name: "App",
  components: {
    Loading,
    Footer,
    TopMenu,
    LeftMenu,
    Breadcrumb,
  },
  mounted() {
    // axios.get(this.apiHost+"/api/v1/config").then((response) => {
    //   console.log(response.data);
    // });
  },
  computed: {
    ...mapGetters(["isLoggedIn", "getIsLoading", "getWrapperIsApp"]),
  },
};
</script>

<style>
</style>
