<template>
  <div class="row g-5 g-xl-10 mb-xl-10">
    <!--begin::Col-->
    <div class="col-lg-12 col-xl-12 col-xxl-6 mb-5 mb-xl-0">
      <!--begin::Timeline widget 3-->
      <div class="card h-md-100">
        <!--begin::Header-->
        <div class="card-header border-0 pt-5">
          <h3 class="card-title align-items-start flex-column">
            <span class="card-label fw-bold text-dark">Todays visits</span>
            <span class="text-muted mt-1 fw-semibold fs-7"
              >Total {{ getAppointments.length }} visits</span
            >
          </h3>
        </div>
        <!--end::Header-->
        <!--begin::Body-->
        <div class="card-body pt-7 px-0">
          <!--begin::Tab Content (ishlamayabdi)-->
          <div class="tab-content mb-2 px-9">
            <!--begin::Tap pane-->
            <div
              class="tab-pane fade show active"
              id="kt_timeline_widget_3_tab_content_4"
            >
              <!--begin::Wrapper-->
              <div
                class="Z align-items-center mb-6 border-b-3 "
                v-for="appointment in getAppointments"
              >
                <HomeAppointment :appointment="appointment" />
                <div class="separator separator-dashed my-5"></div>
              </div>
            </div>
            <!--end::Tap pane-->
          </div>
          <!--end::Tab Content-->
        </div>
        <!--end: Card Body-->
      </div>
      <!--end::Timeline widget 3-->
    </div>
    <!--end::Col-->
    <div class="col-lg-12 col-xl-12 col-xxl-6 mb-5 mb-xl-0">
      <!--begin::Timeline widget 3-->
      <div class="card h-md-100">
        <!--begin::Header-->
        <div class="card-header border-0 pt-5">
          <h3 class="card-title align-items-start flex-column">
            <span class="card-label fw-bold text-dark"
              >Visits in next 10 days days</span
            >
            <span class="text-muted mt-1 fw-semibold fs-7"
              >Total {{ getAppointmentsForWeek.length }} visits</span
            >
          </h3>
        </div>
        <!--end::Header-->
        <!--begin::Body-->
        <div class="card-body pt-7 px-0">
          <!--begin::Tab Content (ishlamayabdi)-->
          <div class="tab-content mb-2 px-9">
            <!--begin::Tap pane-->
            <div
              class="tab-pane fade show active"
              id="kt_timeline_widget_3_tab_content_4"
            >
              <!--begin::Wrapper-->
              <div
                class=" align-items-center mb-6 border-b-3 "
                v-for="appointment in getAppointmentsForWeek"
              >
                <HomeAppointment :appointment="appointment" />
                <div class="separator separator-dashed my-5"></div>
              </div>
            </div>
            <!--end::Tap pane-->
          </div>
          <!--end::Tab Content-->
        </div>
        <!--end: Card Body-->
      </div>
      <!--end::Timeline widget 3-->
    </div>
    <!--end::Col-->
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import moment from "moment";
import HomeAppointment from './partial/HomeAppointment.vue';
export default {
  components: { HomeAppointment, },
  mounted() {
    this.setIsWrapperApp(true);
    this.setApp
    axios
      .get(this.apiHost + "/api/v1/me", {
        headers: {
          Authorization: this.getToken,
          "Cache-Control": "no-cache",
          Pragma: "no-cache",
          Expires: "0",
        },
      })
      .then((response) => { 
        this.setUserFirstName(response.data.data.first_name);
        this.setUserLastName(response.data.data.last_name);
        this.setUserAvatar(response.data.data.avatar);
        this.setAppointments(response.data.data.appointments);
        this.setAppointmentsForWeek(response.data.data.appointments_week);
      })
      .catch((error) => {
        if (error.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          this.doLogout();
          this.$router.push("login");
        } else if (error.request) {
          // The request was made but no response was received
          // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
          // http.ClientRequest in node.js
          this.doLogout();
          this.$router.push("login");
        } else {
          // Something happened in setting up the request that triggered an Error
          console.log("Error", error.message);
        }
        console.log(error.config);
      });
  },
  methods: {
    ...mapActions([
      "setAppointments",
      "setUserFirstName",
      "setUserLastName",
      "setUserAvatar",
      "setAppointmentsForWeek",
      "setIsWrapperApp",
      "logout",
    ]),
    doLogout() {
      this.logout();
    },
  },
  computed: {
    ...mapGetters(["getAppointments", "getAppointmentsForWeek", "getToken"]),
    moment: () => moment,
  },
};
</script> 

<style>
</style>