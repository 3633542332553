import LoadingModule from '@/store/modules/loading'
import WrapperModule from '@/store/modules/wrapper'
import UserModule from '@/store/modules/user'
import {
    createStore
} from 'vuex'
import VuexPersistence from 'vuex-persist'

const vuexLocal = new VuexPersistence({
    storage: window.localStorage,
})

export default createStore({
    modules: {
        LoadingModule,
        UserModule,
        WrapperModule,
    },
    state: {},
    mutations: {}, 
    actions: {},
    plugins: [vuexLocal.plugin]
})