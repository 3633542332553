<template>
  <div class="row">
    <!--begin::Content-->
    <div class="flex-lg-row-fluid">
      <!--begin:::Tabs-->
      <ul
        class="nav nav-custom nav-tabs nav-line-tabs nav-line-tabs-2x border-0 fs-4 fw-semibold mb-8"
      >
        <!--begin:::Tab item-->
        <li class="nav-item">
          <a
            class="nav-link text-active-primary pb-4 active"
            data-bs-toggle="tab"
            href="#pre_arrival"
            >(1) Customer Info</a
          >
        </li>
        <!--end:::Tab item-->
        <!--begin:::Tab item-->
        <li class="nav-item">
          <a
            class="nav-link text-active-primary pb-4"
            data-bs-toggle="tab"
            href="#arrival"
            >(2) We've Arrived</a
          >
        </li>
        <!--end:::Tab item-->
        <!--begin:::Tab item-->
        <li class="nav-item">
          <a
            class="nav-link text-active-primary pb-4"
            data-kt-countup-tabs="true"
            data-bs-toggle="tab"
            href="#to_be_done"
            >(3) Our Tasks for Today</a
          >
        </li>
        <!--end:::Tab item-->
        <!-- begin:::Tab item-->
        <!-- <li class="nav-item">
          <a
            class="nav-link text-active-primary pb-4"
            data-bs-toggle="tab"
            href="#post_work"
            :disabled="!hasCheckedIn && !hasConfirmedToBeDone"
            :class="{'disabled':!hasCheckedIn && !hasConfirmedToBeDone }"
            >(4) Work Completed</a
          >
        </li> -->
        <!--end:::Tab item -->
        <!--begin:::Tab item-->
        <li class="nav-item">
          <a
            class="nav-link text-active-primary pb-4"
            data-bs-toggle="tab"
            href="#departure"
            id="work-completed"
            :disabled="!hasCheckedIn && !hasConfirmedToBeDone"
            :class="{'disabled':!hasCheckedIn && !hasConfirmedToBeDone }"
            >(4) We're Done Here</a
          >
        </li>
        <!--end:::Tab item-->
      </ul>
      <!--end:::Tabs-->
      <!--begin:::Tab content-->
      <div class="tab-content" id="myTabContent">
        <!--begin:::Tab pane-->
        <div class="tab-pane fade show active" id="pre_arrival" role="tabpanel">
          <!--begin::Card-->
          <div class="card pt-4 mb-6 mb-xl-9">
            <!--begin::Card body-->
            <div class="card-body pb-5">
              <!--begin::Item-->
              <!--begin::Details item-->
              <div class="fs-3">
                <strong>Customer:</strong> <span class="text-gray-600">{{ appointment.customer.name }}</span>
              </div>
              <div class="fs-3">
                <strong>Contract details:</strong> <span class="text-gray-600">{{ appointment.display_contract_period }}</span>
              </div>

              <div class="fw-bold mt-6 fs-4">Visit Specific Notes:</div>
              <div class="mb-4 fs-6" v-html="appointment.specific_notes"></div>
              <div v-if="!appointment.specific_notes" class="fa-6">
                No visit specific notes.
              </div>

              <div class="fw-bold mt-6 fs-4">Visit Specific Tasks:</div>
              <ul v-if="appointment.appointment_specific_services.length > 0">
                <li
                  v-for="service in appointment.appointment_specific_services"
                  class="fs-6">
                  - {{ service.label }}
                </li>
              </ul>

              <div class="fw-bold mt-6  fs-4">Other General Customer Information:</div>
              <div class="text-gray-600 fs-6" v-html="appointment.description"></div>
              <div class="text-gray-600 fs-6" v-if="!appointment.description">No customer notes.</div>
              <div class="separator separator-dashed my-5"></div>
          
              <!--begin::Details item-->
              <div class="fw-bold mt-5">Telephone</div>
              <div class="text-gray-600">
                <a
                  :href="'tel:'+appointment.customer.phone"
                  class="text-gray-600 text-hover-primary"
                  v-if="appointment.customer.phone"
                  >{{ appointment.customer.phone }}</a
                >
                <span
                  class="text-gray-600 text-hover-primary text-italic"
                  v-else
                >
                  No telephone number available.
                </span>
              </div>
              <!--begin::Details item-->
              <!--begin::Details item-->
              <div class="fw-bold mt-5">Address</div>
              <div class="text-gray-600" v-if="appointment.address">
                {{ appointment.address.address_line1 }}, <br />
                <span v-if="appointment.address.address_line2">{{ appointment.address.address_line2 }}, <br /></span>
                <span v-if="appointment.address.town_city">{{ appointment.address.town_city }}, <br /></span>
                <span v-if="appointment.address.postal_code">{{ appointment.address.postal_code }}<br /></span>
                <a
                  :href="googleMapsRouteLink"
                  target="_blank"
                  class="mt-3 btn btn-primary btn-sm"
                  >Google Maps
                  <i class="ki-duotone ki-map">
                    <span class="path1"></span>
                    <span class="path2"></span>
                    <span class="path3"></span>
                  </i>
                </a>
              </div>
            </div>
            <!--end::Card body-->
          </div>
          <!--end::Card-->

          <!--begin::Card-->
          <div class="card pt-4 mb-6 mb-xl-9">
            <!--begin::Card header-->
            <div class="card-header border-0">
              <!--begin::Card title-->
              <div class="card-title">
                <h2>Access</h2>
              </div>
              <!--end::Card title-->
            </div>
            <!--end::Card header-->
            <!--begin::Card body-->
            <div class="card-body pt-0 pb-5">
              <!--begin::Table wrapper-->
              <div class="table-responsive">
                <!--begin::Table-->
                <table
                  class="table align-middle table-row-dashed gy-5"
                  id="kt_table_users_login_session"
                >
                  <thead class="border-bottom border-gray-200 fs-7 fw-bold">
                    <tr class="text-start text-muted text-uppercase gs-0">
                      <th width="40%">Type of Access</th>
                      <th width="30%"></th>
                      <th width="30%" class="text-right">Active</th>
                    </tr>
                  </thead>
                  <tbody class="fs-6 fw-semibold text-gray-600">
                    <tr :class="{
                        'border border-success': appointment.is_open_access,
                      }">
                      <td colspan="2" class="border-right-0">Open Access?</td>
                      <td class="text-right border-left-0">
                        <i
                          class="ki-outline fs-1"
                          :class="{
                            'text-danger ki-cross': !appointment.is_open_access,
                            'text-success ki-check': appointment.is_open_access,
                          }"
                        ></i>
                      </td>
                    </tr>
                    <tr :class="{
                        'border border-success': appointment.is_access_by_customer,
                      }">
                      <td colspan="2" class="border-right-0">Access by customer?</td>
                      <td class="text-right border-left-0">
                        <i
                          class="ki-outline fs-1"
                          :class="{
                            'text-danger ki-cross':
                              !appointment.is_access_by_customer,
                            'text-success ki-check':
                              appointment.is_access_by_customer,
                          }"
                        ></i>
                      </td>
                    </tr>
                    <tr :class="{
                        'border border-success': appointment.is_access_by_key,
                      }">
                      <td colspan="2" class="border-right-0">Key Held At GA Yard?</td>
                      <td class="text-right border-left-0">
                        <i
                          class="ki-outline fs-1"
                          :class="{
                            'text-danger ki-cross':
                              !appointment.is_access_by_key,
                            'text-success ki-check':
                              appointment.is_access_by_key,
                          }"
                        ></i>
                      </td>
                    </tr>
                    <tr :class="{
                        'border border-success': appointment.gate_access_code,
                      }">
                      <td class="border-right-0">Gate Code Access?</td>
                      <td class=" border-left-0">
                        <span>
                          {{ appointment.gate_access_code }}
                        </span>
                        <span
                          v-if="!appointment.gate_access_code"
                          class="text-muted text-gray-00"
                        >
                          Not required.
                        </span>
                      </td>
                      <td class="text-right">
                        <i
                          class="ki-outline fs-1"
                          :class="{
                            'text-danger ki-cross':
                              !appointment.is_access_by_gate_code,
                            'text-success ki-check':
                              appointment.is_access_by_gate_code,
                          }"
                        ></i>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <!--end::Table-->
              </div>
              <!--end::Table wrapper-->
            </div>
            <!--end::Card body-->
          </div>
          
        </div>
        <!--end:::Tab pane-->
        <!--begin:::Tab pane-->
        <div class="tab-pane fade" id="arrival" role="tabpanel">
          <!--begin::Card-->
          <div class="card pt-4 mb-6 mb-xl-9">
            <!--begin::Card header-->
            <div class="card-header border-0">
              <!--begin::Card title-->
              <div class="card-title flex-column">
                <h2 class="mb-1">We've Arrived</h2>
                <div class="fs-6 fw-semibold text-muted">
                   Now you have arrived, please &quot;check in&quot; to this visit using the button below.
                </div>
              </div>
              <!--end::Card title-->
            </div>
            <!--end::Card header-->
            <!--begin::Card body-->
            <div class="card-body pb-5">
              <div class="d-flex">
                <a
                  href="#"
                  @click.prevent="arriving(appointment.id)"
                  class="d-flex flex-column btn btn-sm btn-primary me-3"
                  :class="{ 'disabled': hasAlreadyArrived }"
                  >We've arrived</a
                >
              </div>
            </div>
            <!--end::Card body-->
          </div>
          <!--end::Card-->

          <!--begin::Card-->
          <div class="card pt-4 mb-6 mb-xl-9">
            <!--begin::Card header-->
            <div class="card-header border-0">
              <!--begin::Card title-->
              <div class="card-title">
                <h2>Logs</h2>
              </div>
              <!--end::Card title-->
            </div>
            <!--end::Card header-->
            <!--begin::Card body-->
            <div class="card-body py-0">
              <!--begin::Table wrapper-->
              <div class="table-responsive">
                <!--begin::Table-->
                <table
                  class="table align-middle table-row-dashed fw-semibold text-gray-600 fs-6 gy-5"
                  id="kt_table_users_logs"
                >
                  <tbody>
                    <tr v-for="action in appointment.activity">
                      <td class="min-w-70px">
                        <div class="badge badge-light-warning">
                          {{ action.event }}
                        </div>
                      </td>
                      <td>{{ action.event_by }}</td>
                      <td class="pe-0 text-end min-w-200px">
                        {{
                          moment(action.created_at).format(
                            "DD MMM YYYY hh:mm a"
                          )
                        }}
                      </td>
                    </tr>
                  </tbody>
                </table>
                <!--end::Table-->
              </div>
              <!--end::Table wrapper-->
            </div>
            <!--end::Card body-->
          </div>
          <!--end::Card-->
        </div>
        <!--end:::Tab pane-->
        <!--begin:::Tab pane-->
        <div class="tab-pane fade" id="to_be_done" role="tabpanel">
          <!--begin::Card-->
          <div class="card pt-4 mb-6 mb-xl-9">
            <!--begin::Card header-->
            <div class="card-header border-0">
              <!--begin::Card title-->
              <div class="card-title flex-column">
                <h2 class="mb-1">Visit specific Notes</h2>
                <div class="fs-6 fw-semibold text-muted">
                  Notes &amp; information for this specific visit
                </div>
              </div>
              <!--end::Card title-->
            </div>
            <!--end::Card header-->
            <!--begin::Card body-->
            <div class="card-body pb-5">
              <div class="mb-4" v-html="appointment.specific_notes"></div>
              <div v-if="!appointment.specific_notes">
                No visit specific notes.
              </div>
              <div v-else>
                <!--begin::Item-->
                <div
                  class="d-flex align-items-center position-relative mb-7"
                >
                  <!--begin::Label-->
                  <div
                    class="position-absolute top-0 start-0 rounded h-100 bg-secondary w-4px"
                  ></div>
                  <!--end::Label-->
                  <!--begin::Checkbox-->
                  <div
                    class="form-check form-check-custom form-check-solid ms-6 me-4"
                  >
                    <input
                      class="form-check-input"
                      type="checkbox"
                      value=""
                      @change="acknowledgedNotes()"
                      :checked="appointment.acknowledged_notes"
                    />
                  </div>
                  <!--end::Checkbox-->
                  <!--begin::Details-->
                  <div class="fw-semibold">
                    <span class="fs-6 fw-bold text-gray-900 text-hover-primary">Tick to acknolwedge this note / information.</span>
                    <!--begin::Info-->
                    <div class="text-gray-400"></div>
                    <!--end::Info-->
                  </div>
                  <!--end::Details-->
                </div>
                <!--end::Item-->
              </div>
            </div>
            <!--end::Card body-->
          </div>
          <!--end::Card-->

          <!--begin::Card-->
          <div class="card pt-4 mb-6 mb-xl-9">
            <!--begin::Card header-->
            <div class="card-header border-0">
              <!--begin::Card title-->
              <div class="card-title flex-column">
                <h2 class="mb-1">Visit Specific Tasks</h2>
                <div class="fs-6 fw-semibold text-muted">
                  These specific tasks are required for completion today
                </div>
              </div>
              <!--end::Card title-->
            </div>
            <!--end::Card header-->
           <div class="card-body d-flex flex-column mb-9 p-9 pt-3 width-half background-red">
              <!--begin::Item-->
              <div v-if="appointment.appointment_specific_services.length > 0">
              <div
                class="d-flex align-items-center position-relative mb-7"
                v-for="availableTask in appointment.appointment_specific_services"
              >
                <!--begin::Label-->
                <div
                  class="position-absolute top-0 start-0 rounded h-100 bg-secondary w-4px"
                ></div>
                <!--end::Label-->

                <!--begin::Checkbox-->
                <div
                  class="form-check form-check-custom form-check-solid ms-6 me-4"
                >
                  <input
                    class="form-check-input"
                    type="checkbox"
                    value=""
                    @change="toggleAvailableTask(availableTask, appointment.id)"
                    :checked="hasBeenSelected(availableTask)"
                  />
                </div>
                <!--end::Checkbox-->

                <!--begin::Details-->
                <div class="fw-semibold">
                  <span class="fs-6 fw-bold text-gray-900 text-hover-primary">{{
                    availableTask.label
                  }}</span>
                  <!--begin::Info-->
                  <div class="text-gray-400"></div>
                  <!--end::Info-->
                </div>
                <!--end::Details-->
              </div>
              <!--end::Item-->
              </div>
              <div v-else>
                <p>There are no specific tasks today, just normal general customer tasks</p>
              </div>
            </div>
          </div>
          <!--end::Card-->

          <!--begin::Tasks-->
          <div class="card card-flush mb-6 h-lg-100">
            <!--begin::Card header-->
            <div class="card-header mt-6">
              <!--begin::Card title-->
              <div class="card-title flex-column">
                <h3 class="fw-bold mb-1">Customer Task Options</h3>

                <div class="fs-6 text-gray-400">
                  Please tick the tasks you completed today
                </div>
              </div>
              <!--end::Card title-->
            </div>
            <!--end::Card header-->

            <!--begin::Card body-->
            <div class="card-body d-flex flex-column mb-9 p-9 pt-3 width-half background-red">
              <!--begin::Item-->
              <div
                class="d-flex align-items-center position-relative mb-7"
                v-for="availableTask in appointment.contracted_tasks"
              >
                <!--begin::Label-->
                <div
                  class="position-absolute top-0 start-0 rounded h-100 bg-secondary w-4px"
                ></div>
                <!--end::Label-->

                <!--begin::Checkbox-->
                <div
                  class="form-check form-check-custom form-check-solid ms-6 me-4"
                >
                  <input
                    class="form-check-input"
                    type="checkbox"
                    value=""
                    @change="toggleAvailableTask(availableTask, appointment.id)"
                    :checked="hasBeenSelected(availableTask)"
                  />
                </div>
                <!--end::Checkbox-->

                <!--begin::Details-->
                <div class="fw-semibold">
                  <span class="fs-6 fw-bold text-gray-900 text-hover-primary">{{
                    availableTask.label
                  }}</span>
                  <!--begin::Info-->
                  <div class="text-gray-400"></div>
                  <!--end::Info-->
                </div>
                <!--end::Details-->
              </div>
              <!--end::Item-->
            </div>
          </div>

          <!--begin::Tasks-->
          <div class="card card-flush mb-6 h-lg-100">
            <!--begin::Card header-->
            <div class="card-header mt-6">
              <!--begin::Card title-->
              <div class="card-title flex-column">
                <h3 class="fw-bold mb-1">Additional Notes</h3>

                <div class="fs-6 text-gray-400">
                  Make notes specific to this visit.
                </div>
              </div>
              <!--end::Card title-->
            </div>
            <!--end::Card header-->

            <!--begin::Card body-->
            <div class="card-body d-flex flex-column mb-9 p-9 pt-3">
              <!--begin::Item-->

              <!--begin::Input group-->
              <div class="row mb-4">

                  <!--begin::Editor-->
                  <!-- <div id="kt_ecommerce_add_product_description" :model="additionalNotes" name="kt_ecommerce_add_product_description" class="min-h-200px mb-2"></div> -->
                  <QuillEditor theme="snow" v-model:content="appointment.additional_notes" content-type="html" @update:content="updateAdditionalNotes"  /> 
                  <!--end::Editor-->

                  <!--begin::Description-->
                  <div class="text-muted fs-7">Note anything additional.</div>
                  <!--end::Description-->
              </div>
              <!--end::Input group-->

            </div>
            <!--end::Card body-->
          </div>
          <!--end::Tasks-->

          <!--begin::Tasks-->
          <div class="card card-flush mb-6 h-lg-100">
            <!--begin::Card header-->
            <div class="card-header mt-6">
              <!--begin::Card title-->
              <div class="card-title flex-column">
                <h3 class="fw-bold mb-1">Photo Upload</h3>
                <div class="fs-6 text-gray-400">
                  Upload photo evidence
                </div>
              </div>
              <!--end::Card title-->
            </div>
            <!--end::Card header-->

            <!--begin::Card body-->
            <div class="card-body d-flex flex-column mb-9 p-9 pt-3">
              <DropZone
                class="drop-area"
                @files-dropped="addFiles"
                #default="{ dropZoneActive }"
              >
                <label for="file-input text-center ">
                  <span v-if="dropZoneActive">
                    <div class="fs-7 fw-semibold text-gray-400">
                      Drag and drop files here
                    </div>
                  </span>
                  <span v-else>
                    <div class="fs-7 fw-semibold text-gray-400">
                      Drag and drop files here
                      <span class="smaller">
                        or <strong><em>click here</em></strong> to select files
                      </span>
                    </div>
                  </span>

                  <input
                    type="file"
                    id="file-input"
                    accept="image/*"
                    @change="onInputChange"
                    multiple  
                  />
                </label>
                <ul class="image-list" v-show="files.length">
                  <FilePreview
                    v-for="file of files"
                    :key="file.id"
                    :file="file"
                    tag="li"
                    @remove="removeFile"
                  />
                </ul>
              </DropZone> 
              <button @click.prevent="uploadFiles(files)" class="upload-button mt-3 btn btn-primary btn-sm">
                Upload
              </button>

            </div>
             <!--begin::Card header-->
            <div class="card-header mt-6">
              <!--begin::Card title-->
              <div class="card-title flex-column">
                <h3 class="fw-bold mb-1">Uploaded photos</h3>

                <div class="fs-6 text-gray-400">
                  All previously uploaded photos for this visit.
                </div>
              </div>
              <!--end::Card title-->
            </div>
            <!--end::Card header-->
            
            <div class="card-body d-flex flex-column mb-9 p-9 pt-3">
              <div class="grid grid-cols-2 md:grid-cols-3 gap-4" v-show="appointment.files.length">
                <div v-for="image in appointment.files" :key="image">
                  <img class="h-auto max-w-full rounded-lg" :src="image.path" alt="" title="" />
                </div>  
              </div>
            </div>
          </div>

          <!--begin::Tasks-->
          <div class="card card-flush mb-6 h-lg-100">
            <!--begin::Card header-->
            <div class="card-header mt-6">
              <!--begin::Card title-->
              <div class="card-title flex-column">
                <h3 class="fw-bold mb-1">Once you've finished</h3>
              </div>
              <!--end::Card title-->
            </div>
            <!--end::Card header-->

            <!--begin::Card body-->
            <div class="card-body d-flex flex-column mb-9 p-9 pt-3">
              
              <!--begin::Notice-->
              <div class="notice d-flex bg-light-warning rounded border-warning border border-dashed mb-9 p-6">
                <!--begin::Icon-->
                <i class="ki-duotone ki-information fs-2tx text-warning me-4"><span class="path1"></span><span class="path2"></span><span class="path3"></span></i>        
                <!--end::Icon-->

                <!--begin::Wrapper-->
                <div class="d-flex flex-stack flex-grow-1 ">
                <!--begin::Content-->
                <div class=" fw-semibold">
                  <h4 class="text-gray-900 fw-bold">Declaration</h4>
                  <div class="fs-6 text-gray-700 ">All nessasary work has been completed for this visit, are now preparing to leave (or finishing up).</div>
                </div>
                <!--end::Content-->
                </div>
                <!--end::Wrapper-->  
              </div>
              <!--end::Notice-->

              <a href="#"
                  @click.prevent="workCompleted()"
                  class="mt-3 btn btn-warning btn-sm">Work completed</a>

            </div>
            <!--end::Card body-->
          </div>
          <!--end::Tasks-->

        </div>
        <!--end:::Tab pane-->
        <!--begin:::Tab pane-->
        <!-- <div class="tab-pane fade" id="post_work" role="tabpanel">
          <div class="card pt-4 mb-6 mb-xl-9" v-if="appointment.appointment_services.length == 0">
            <div class="card-header border-0">
              <div class="card-title">
                <h2>Nothing in To-Do list.</h2>
              </div>
            </div>
            <div class="card-body pt-0 pb-5">
              <div class="d-flex flex-row">
                <p>Nothing has been selected from the &quot;To-be-done&quot; list.</p>
              </div>
            </div>
          </div>
          <div
            class="card pt-4 mb-6 mb-xl-9"
            v-for="service in appointment.appointment_services"
          >
            <div class="card-header border-0">
              <div class="card-title">
                <h2>{{ service.label }}</h2>
              </div>
            </div>
            <div class="card-body pt-0 pb-5">
              <div class="d-flex flex-row">
                <div class="d-flex w-50 flex-column p-3">
                  <h4>Before-Work Photos</h4>
                  <DropZone
                    class="drop-area"
                    @files-dropped="addFiles"
                    #default="{ dropZoneActive }"
                  >
                    <label for="file-input text-center ">
                      <span v-if="dropZoneActive">
                        <div class="fs-7 fw-semibold text-gray-400">
                          Drag and drop files here
                        </div>
                      </span>
                      <span v-else>
                        <div class="fs-7 fw-semibold text-gray-400">
                          Drag and drop files here
                          <span class="smaller">
                            or <strong><em>click here</em></strong> to select files
                          </span>
                        </div>
                      </span>

                      <input
                        type="file"
                        id="file-input"
                        accept="image/*"
                        capture="user"
                        @change="onInputChange"
                        multiple="multiple" 
                      />
                    </label>
                    <ul class="image-list" v-show="service.files.length">
                      <FilePreview
                        v-for="file of service.files"
                        :key="file.id"
                        :file="file"
                        tag="li"
                        @remove="removeFile"
                      />
                    </ul>
                  </DropZone>
                  <button @click.prevent="uploadFiles(service.files)" class="upload-button">
                    Upload
                  </button>
                  <div class="text-muted fs-7">
                    Set the product media gallery.
                  </div>
                </div>
                <div class="d-flex w-50 flex-column p-3">
                  <h4>After-work Photos</h4>
                  <div class="text-muted fs-7">
                    Set the product media gallery.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div> -->
        <!--end:::Tab pane-->
        <!--begin:::Tab pane-->
        <div class="tab-pane fade" id="departure" role="tabpanel">
          <!--begin::Tasks-->
          <div class="card card-flush h-lg-100">
            <!--begin::Card header-->
            <div class="card-header mt-6">
              <!--begin::Card title-->
              <div class="card-title flex-column">
                <h3 class="fw-bold mb-1">Before leaving</h3>
                <div class="fs-6 text-gray-400">We're Done Checklist</div>
              </div>
              <!--end::Card title-->
            </div>
            <!--end::Card header-->

            <!--begin::Card body-->
            <div class="card-bodymb-9 p-9 pt-3">
              <div class="row mb-6">
                <div class="col-12">
                  <!--begin::Input group-->
                  <div class="row mb-4">
                      <!--begin::Label-->
                      <label class="col-lg-4 col-form-label fw-semibold fs-6">Any billable items?</label>
                      <!--begin::Label-->

                      <!--begin::Label-->
                      <div class="col-lg-8 d-flex align-items-center">
                          <div class="form-check form-check-solid form-switch form-check-custom fv-row">
                              <input class="form-check-input w-45px h-30px" type="checkbox" id="used_consumables" @change="toggleProductsUsed()"  />
                              <label class="form-check-label" for="used_consumables"></label>
                          </div>
                      </div>
                      <!--begin::Label-->

                      <div class="col-12" v-if="hasUsedProducts">
                          <!--begin::Item-->
                          <div
                            class="d-flex align-items-center position-relative mb-7"
                            v-for="availableProduct in available_products" :key="availableProduct"
                          >
                            <!--begin::Label-->
                            <div
                              class="position-absolute top-0 start-0 rounded h-100 bg-secondary w-4px"
                            ></div>
                            <!--end::Label-->

                            <!--begin::Checkbox-->
                            <div
                              class="form-check form-check-custom form-check-solid ms-6 me-4"
                            >
                              <input
                                class="form-check-input"
                                type="checkbox"
                                value=""
                                @change="toggleAvailableProducts(availableProduct)"
                                :checked="productHasBeenSelected(availableProduct)"
                              />
                            </div>
                            <!--end::Checkbox-->

                            <!--begin::Details-->
                            <div class="fw-semibold w-400px">
                              <span class="fs-6 fw-bold text-gray-900 text-hover-primary">{{
                                availableProduct.label
                              }}</span>
                              <!--begin::Info-->
                              <div class="text-gray-400">
                                {{ availableProduct.description }}
                              </div>
                              <!--end::Info-->
                            </div>
                            <!--end::Details-->

                            <!--begin::Details-->
                            <div class="row">
                              <!--begin::Col-->
                              <div class="col-lg-6 fv-row">
                                <input type="number" name="fname" class="form-control form-control-lg form-control-solid mb-3 mb-lg-0" 
                                :disabled="!confirmed_products.find((item) => item.id === availableProduct.id)"
                                @change="changeProductAmount($event, availableProduct)"
                                placeholder="0" step="0.25" min="0"
                                 />
                              </div>
                              <!--end::Col-->
                          </div>
                            <!--end::Details-->
                            
                          </div>
                          <!--end::Item-->
                      </div>
                  </div>
                  <!--end::Input group-->
                                    
                </div>
              </div>
              
              <div class="separator separator-dashed my-5"></div>
              <div class="row">
                <div class="col-12">
                  <!--begin::Card title-->
                  <div class="card-title flex-column">
                    <h3 class="fw-bold mb-1">Future visit notes</h3>

                    <div class="fs-6 text-gray-400">
                      Add notes to specific future visits.
                    </div>
                  </div>
                  <!--end::Card title-->
                  <!--begin::Item-->
                  <div
                    class="align-items-center position-relative mb-7"
                    v-for="(nextAppointment, key) in appointment.contract.futureAppointments" :key="key"
                  >
                    <div class="d-block w-full pl-3">
                    <!--begin::Label-->
                    <div
                      class="position-absolute top-0 start-0 rounded h-100 bg-secondary w-4px"
                    ></div>
                    <!--end::Label-->
                    
                    <!--begin::Checkbox-->
                    <div
                      class="form-check form-check-custom form-check-solid ms-6 me-4"
                    >
                      <input class="form-check-input" type="checkbox" value="" @change="toggleFutureNote(nextAppointment)" />
                    </div>
                    <!--end::Checkbox-->

                    <!--begin::Details-->
                    <div class="fw-semibold w-400px">
                      <span class="fs-6 fw-bold text-gray-900 text-hover-primary">{{
                        nextAppointment.label
                      }} (#{{ nextAppointment.id}})</span>
                    </div>
                    <!--end::Details-->
                    </div>
                    <div v-if="hasAdditionalNote(nextAppointment)" class="d-block w-full  pl-3"> 
                      <QuillEditor theme="snow" v-model:content="nextAppointment.note" content-type="html" @update:content="updateFutureNotes($event, nextAppointment)"  /> 
                    </div>
                  </div>
                  <!--end::Item-->
                </div>
              </div>

              <div class="separator separator-dashed my-5"></div>

              <!--begin::Notice-->
              <div class="notice d-flex bg-light-warning rounded border-warning border border-dashed mb-9 p-6">
                <!--begin::Icon-->
                <i class="ki-duotone ki-information fs-2tx text-warning me-4"><span class="path1"></span><span class="path2"></span><span class="path3"></span></i>        
                <!--end::Icon-->

                <!--begin::Wrapper-->
                <div class="d-flex flex-stack flex-grow-1 ">
                <!--begin::Content-->
                <div class=" fw-semibold">
                  <h4 class="text-gray-900 fw-bold">Read carefully</h4>
                  <div class="fs-6 text-gray-700 ">In order to wrap up this visit, you must do the following tasks.</div>
                </div>
                <!--end::Content-->
                </div>
                <!--end::Wrapper-->  
              </div>
              <!--end::Notice-->

              <div class="d-flex flex-column">
                <div class="d-flex align-items-center position-relative mb-7" v-for="action in appointment.required_actions">
                  <!--begin::Label-->
                  <div
                    class="position-absolute top-0 start-0 rounded h-100 bg-secondary w-4px"
                  ></div>
                  <!--end::Label-->

                  <!--begin::Checkbox-->
                  <div
                    class="form-check form-check-custom form-check-solid ms-6 me-4"
                  >
                    <input class="form-check-input" type="checkbox" value="" @change="toggleRequiredTask(action)" />
                  </div>
                  <!--end::Checkbox-->

                  <!--begin::Details-->
                  <div class="fw-semibold">
                    <a
                      href="#"
                      class="fs-6 fw-bold text-gray-900 text-hover-primary"
                      >{{ action.label }}</a>

                    <!--begin::Info-->
                    <div class="text-gray-400">
                      {{ action.description }}
                    </div>
                    <!--end::Info-->
                  </div>
                  <!--end::Details-->
                </div>
                <!--end::Item-->
              </div>

              <div class="separator separator-dashed my-5"></div>

              <div class="text-center">
                <p class="text-muted"><small>Once all actions have been confirmed, the Leave button will be clickable</small></p>
                <a href="#"
                  :class="{ 'disabled': !canLeave }"
                  @click.prevent="leaving(appointment.id)"
                  class="mt-3 btn btn-warning btn-sm">Leaving</a>
              </div>
            </div>
            <!--end::Card body-->
          </div>
          <!--end::Tasks-->
        </div>
        <!--end:::Tab pane-->
      </div>
      <!--end:::Tab content-->
    </div>
    <!--end::Content-->
  </div>
</template>

<script setup>
// Components
import DropZone from "@/components/DropZone.vue";
import FilePreview from "@/components/FilePreview.vue";
</script>

<script>
import { mapGetters } from "vuex";
import moment from "moment";
import createUploader from "@/compositions/file-uploader";
import useFileList from "@/compositions/file-list";
const { files, addFiles, removeFile } = useFileList();
import { QuillEditor } from '@vueup/vue-quill'
import '@vueup/vue-quill/dist/vue-quill.snow.css';
import debounce from 'lodash.debounce'

export default {
  components: { DropZone, FilePreview, QuillEditor },
  data() {
    return {
      hasUsedProducts: false,
      hasWaste: false,
      additionalNotes: '',
      available_products: [],
      confirmed_products: [],
      chosenTasks: [],
      currentAppointment: 0,
      confirmed_required_actions: [],
      future_notes: [],
      appointment: {
        acknowledged_notes: false,
        specific_notes: '',
        additional_notes: '',
        has_arrived_action: false,
        required_actions: [],
        appointment_services: [],
        available_tasks: [],
        contracted_tasks: [],
        appointment_specific_services: [],
        display_contract_period: '',
        files: [],
        label: "",
        id: "",
        start_at: null,
        end_at: null,
        address: {},
        customer: {
          name: "",
          phone: "",
          mobile: "",
        },
        contract: {
          futureAppointments: [],
          hourly_rate: 0,
        },
        users: [],
      },
    };
  },
  mounted() {
    this.currentAppointment = parseInt(this.$route.params.appointmentId);
    this.appointmentData();
    this.getProducts();
  },
  methods: {
    updateFutureNotes: debounce(function(event, nextAppointment) {
      axios.post(this.apiHost + "/api/v1/appointment/" + nextAppointment.id + "/specific_notes", 
        { notes: event }, 
        { headers: { Authorization: this.getToken }}).then(() => {
      }).catch((response) => {});
      toastr.success("Additional Notes", "Additional notes saved to appointment");
    }, 2000),
    updateAdditionalNotes: debounce(function(event) {
      this.appointment.additional_notes = event;
      axios.post(this.apiHost + "/api/v1/appointment/" + this.appointment.id + "/additional_notes", 
        { additional_notes: this.appointment.additional_notes }, 
        { headers: { Authorization: this.getToken }}).then(() => {
          this.appointmentData();
      }).catch((response) => {});
      toastr.success("Additional Notes", "Additional notes saved to appointment");
    }, 2000),
    getProducts() {
      axios.get(this.apiHost + "/api/v1/products")
        .then((response) => {
          this.available_products = response.data.data
        }).catch((response) => {});
    },
    confirmChosenTasks(appointmentId) {
      axios
        .post(
          this.apiHost + "/api/v1/appointment/" + appointmentId + "/services",
          { services: this.chosenTasks },
          {
            headers: { Authorization: this.getToken },
          }
        )
        .then(() => {
          this.appointmentData();
        })
        .catch((response) => {});
      toastr.success("Visit services saved.");
    },
    currency(value) {
      if (typeof value !== "number") {
        return value;
      }
      var formatter = new Intl.NumberFormat("en-GB", {
        style: "currency",
        currency: "GBP",
      });
      return formatter.format(value);
    },
    appointmentData() {
      axios
        .get(
          this.apiHost +
            "/api/v1/appointment/" +
            this.currentAppointment +
            "/details"
        )
        .then((response) => {
          this.appointment = response.data.data;
          this.chosenTasks = this.appointment.appointment_services;
          if(this.appointment.specific_notes.length === 0) {
            this.appointment.acknowledged_notes = true;
          }
        });
    },
    onInputChange(e) {
      addFiles(e.target.files);
      e.target.value = null; // reset so that selecting the same file again will still cause it to fire this change
    },
    uploadFiles(files) {
      createUploader(
        this.apiHost + "/api/v1/appointment/" + this.appointment.id + "/upload"
      ).uploadFiles(files).then(() => {
        this.appointmentData();
      });
    },
    arriving(appointmentId) {
      axios.post(this.apiHost + "/api/v1/appointment/" + appointmentId + "/arrive", { headers: { Authorization: this.getToken }}).then(() => {
          this.appointmentData();
      }).catch((response) => {});
      toastr.success("Arrival Logged", "Date &amp; Time Logged");
    },
    leaving(appointmentId) {
      axios
        .post(
          this.apiHost + "/api/v1/appointment/" + appointmentId + "/leave",
          {
            headers: { Authorization: this.getToken },
          }
        )
        .then(() => {
          this.appointmentData();
          setTimeout(() => {
            this.$router.push("/");
          }, 3000);
        })
        .catch((response) => {});
      toastr.success("Departure Logged", "Date &amp; Time Logged");
    },
    hasBeenSelected(availableTask) {
      return this.appointment.appointment_services.find(
        (item) => item.id === availableTask.id
      );
    },
    productHasBeenSelected(availableProduct) {
      return this.confirmed_products.find(
        (item) => item.id === availableProduct.id
      );
    },
    toggleAvailableProducts(availableProduct) {
      this.confirmed_products.find((item) => item.id === availableProduct.id)
        ? (this.confirmed_products = this.confirmed_products.filter(
            (item) => item.id !== availableProduct.id
          ))
        : this.confirmed_products.push(availableProduct);
    },
    toggleProductsUsed() {
      this.hasUsedProducts = !this.hasUsedProducts;
    },
    hasAdditionalNote(additionalNote)  {
      return this.future_notes.find(
        (item) => item.id === additionalNote.id
      ); 
    },
    toggleFutureNote(futureNote) {
      this.future_notes.find((item) => item.id === futureNote.id)
        ? (this.future_notes = this.future_notes.filter(
            (item) => item.id !== futureNote.id
          ))
        : this.future_notes.push(futureNote);
    },
    toggleAvailableTask(availableTask, appointmentId) {
      this.chosenTasks.find((item) => item.id === availableTask.id)
        ? (this.chosenTasks = this.chosenTasks.filter(
            (item) => item.id !== availableTask.id
          ))
        : this.chosenTasks.push(availableTask);
      this.confirmChosenTasks(appointmentId);
    },
    toggleRequiredTask(requiredTask) {
      this.confirmed_required_actions.find((item) => item.id === requiredTask.id)
        ? (this.confirmed_required_actions = this.confirmed_required_actions.filter(
            (item) => item.id !== requiredTask.id
          ))
        : this.confirmed_required_actions.push(requiredTask);
    },
    changeProductAmount(event, availableProduct) {
      let indexPos = this.confirmed_products.findIndex((item) => item.id === availableProduct.id);
      this.confirmed_products[indexPos]['amount'] = parseFloat(event.target.value);
      this.storeConfirmedProducts()
    },
    storeConfirmedProducts() {
      axios
        .post(
          this.apiHost + "/api/v1/appointment/" + this.appointment.id + "/confirmed_products",
          {
            products: this.confirmed_products
          },
          {
            headers: { Authorization: this.getToken },
          }
        )
        .then(() => {
          this.appointmentData();
        })
        .catch((response) => {});
      toastr.success("Consumables", "Consumables logged");
    },
    acknowledgedNotes() {
      this.appointment.acknowledged_notes = !this.appointment.acknowledged_notes;
      axios
        .post(
          this.apiHost + "/api/v1/appointment/" + this.appointment.id + "/acknowledge",
          {
            headers: { Authorization: this.getToken },
          }
        )
        .then(() => {
          this.appointmentData();
        })
        .catch((response) => {});
      toastr.success("Notes acknowledged", "Date &amp; Time Logged");
    },
    workCompleted() {
      document.getElementById("work-completed").click();
      window.location.hash = "top";
    },
  },
  computed: {
    ...mapGetters(["getAppointments", "getToken",]),
    moment: () => moment,
    hasAlreadyArrived() {
      return this.appointment.has_arrived_action;
    },
    hasCheckedIn() {
      return this.appointment.has_arrived_action;
    },
    hasConfirmedProducts() {
      return this.confirmed_products.length > 0;
    },
    hasConfirmedToBeDone() {
      return this.chosenTasks.length > 0;
    },
    canLeave() {
      let score = 0;
      let target = 1;
      target = target + this.confirmed_products.length;
      for(let i = 0; i < this.confirmed_products.length; i++) {
        if(this.confirmed_products[i].amount > 0) {
          score = score + 1;
        }
      }
      if(this.appointment.required_actions.length === this.confirmed_required_actions.length) {
        score = score + 1;
      }
      console.log(score, target);
      return score == target;
    },
    googleMapsRouteLink() {
      var string =
        this.appointment.address.address_line1 +
        " " +
        this.appointment.address.postal_code;
      return "https://www.google.com/maps/dir//" + encodeURI(string) + "/"; // double slash after 'dir' + end slash
    },
  },
  watch: {
    appointmentId: {
      handler() {
        this.appointmentData();
      },
    },
    confirmed_products: {
      deep: true
    },
    future_notes: {
      deep: true
    }
  },
};
</script>

<style>
</style>