<template>
  <div class="row">
  
    <div class="d-flex flex-column flex-xl-row">
      <!--begin::Sidebar-->
      <div class="flex-column flex-lg-row-auto w-100 w-xl-350px mb-10">
        <!--begin::Card-->
        <div class="card mb-5 mb-xl-8">
          <!--begin::Card body-->
          <div class="card-body pt-15">
            <!--begin::Summary-->
            <div class="d-flex flex-center flex-column mb-5">
              <!--begin::Name-->
              <a
                href="#"
                class="fs-3 text-gray-800 text-hover-primary fw-bold mb-1"
              >
                {{ appointment.customer.name }}
              </a>
              <!--end::Name-->

              <!--begin::Position-->
              <div class="fs-5 fw-semibold text-muted mb-6">
                Customer
              </div>
              <!--end::Position-->

              <!--begin::Info-->
              <div class="d-flex flex-wrap flex-center">
                <!--begin::Stats-->
                <div
                  class="border border-gray-300 border-dashed rounded py-3 px-3 mb-3"
                >
                  <div class="fs-4 fw-bold text-gray-700">
                    <span class="w-75px">{{ moment(appointment.start_at).format("h:mmA") }}</span>
                  </div>
                  <div class="fw-semibold text-muted">Start At</div>
                </div>
                <!--end::Stats-->

                <!--begin::Stats-->
                <div
                  class="border border-gray-300 border-dashed rounded py-3 px-3 mx-4 mb-3"
                >
                  <div class="fs-4 fw-bold text-gray-700">
                    <span class="w-50px">130</span>
                    <i class="ki-duotone ki-arrow-down fs-3 text-danger"
                      ><span class="path1"></span><span class="path2"></span
                    ></i>
                  </div>
                  <div class="fw-semibold text-muted">Tasks</div>
                </div>
                <!--end::Stats-->
              </div>
              <!--end::Info-->

              <div class="d-flex">
                <a
                href="#"
                @click.prevent="arriving(appointment.id)"
                class="d-flex flex-column btn btn-sm btn-primary me-3">Arriving</a>
                <a
                  href="#"
                  @click.prevent="leaving(appointment.id)"
                  class="d-flex flex-column btn btn-sm btn-warning me-3">Leaving</a>
              </div>

            </div>
            <!--end::Summary-->

            <!--begin::Details toggle-->
            <div class="d-flex flex-stack fs-4 py-3">
              <div
                class="fw-bold rotate collapsible"
                href="#"
              >
                Details
              </div>
            </div>
            <!--end::Details toggle-->

            <div class="separator separator-dashed my-3"></div>

            <!--begin::Details content-->
            <div id="kt_customer_view_details" class="">
              <div class="py-5 fs-6">
                 <div class="symbol-group symbol-hover mb-3">
                <!--begin::User-->
                <div
                  class="symbol symbol-35px symbol-circle"
                  data-bs-toggle="tooltip"
                  title=""
                  v-for="user in appointment.users"
                >
                  <img
                    v-if="user.avatar.length > 0"
                    alt="Pic"
                    :src="user.avatar"
                    width="35"
                    height="35"
                  />
                  <span
                    v-else
                    class="symbol-label bg-warning text-inverse-warning fw-bold"
                  >
                    {{ user.first_letter }}
                  </span>
                </div>
                <!--end::User-->
              </div>
                <!-- begin::Badge-->
                <!-- <div class="badge badge-light-info d-inline">Premium user</div> -->
                <!--begin::Badge-->

                <!--begin::Details item-->
                <div class="fw-bold mt-5">Account ID</div>
                <div class="text-gray-600">ID-{{ appointment.customer.id }}</div>
                <!--begin::Details item-->
                <!--begin::Details item-->
                <div class="fw-bold mt-5">Telephone</div>
                <div class="text-gray-600">
                  <a href="#" class="text-gray-600 text-hover-primary" v-if="appointment.customer.telephone"
                    >{{ appointment.customer.telephone }}</a
                  >
                  <span class="text-gray-600 text-hover-primary text-italic" v-else>
                    No telephone number available.
                  </span>
                </div>
                <!--begin::Details item-->
                <!--begin::Details item-->
                <div class="fw-bold mt-5">Address</div>
                <div class="text-gray-600" v-if="appointment.address">
                  {{ appointment.address.address_line1 }}, <br />
                  {{ appointment.address.address_line2}}, <br />
                  {{ appointment.address.town_city }}, <br />
                  {{ appointment.address.postal_code}}<br />
              <a
                :href="googleMapsRouteLink"
                target="_blank"
                class="mt-3 btn btn-primary btn-sm"
                >Google Maps
                <i class="ki-duotone ki-map">
                  <span class="path1"></span>
                  <span class="path2"></span>
                  <span class="path3"></span>
                </i>
              </a>
                </div>
                <!--begin::Details item-->
                <!--begin::Details item-->
                <div class="fw-bold mt-5">Rate (&pound;)</div>
                <div class="text-gray-600">{{ currency(appointment.contract.hourly_rate) }}</div>
                <!--begin::Details item-->
                <!--begin::Details item-->
                <!-- <div class="fw-bold mt-5">Upcoming Invoice</div> -->
                <!-- <div class="text-gray-600">54238-8693</div> -->
                <!--begin::Details item-->
                <!--begin::Details item-->
                <!-- <div class="fw-bold mt-5">Tax ID</div> -->
                <!-- <div class="text-gray-600">TX-8674</div> -->
                <!--begin::Details item -->
              </div>
            </div>
            <!--end::Details content-->
          </div>
          <!--end::Card body-->
        </div>
        <!--end::Card-->
        
      </div>
      <!--end::Sidebar-->
      <div class="flex-lg-row-fluid ms-lg-15">

          <div class="card mb-4">
            <div class="card-body">
              <h3 class="fw-bold mt-5">General notes:</h3>
              <div
                  class="mb-4"
                v-html="appointment.description">
              </div>
              <h3 class="fw-bold mt-5">Appointment-Specific notes:</h3>
              <div class="mb-4"
                v-html="appointment.specific_notes">
              </div>  
          </div>
        </div>              
          <!--begin::Card-->
          <div
            class="card flex-center bg-light-primary border-primary border border-dashed p-8 mb-4"
          >
            <!--begin::Image-->
            <img src="/assets/media/svg/files/upload.svg" class="mb-5" alt="" />
            <!--end::Image-->
            <!--begin::Link-->
            <a href="#" class="text-hover-primary fs-5 fw-bold mb-2">File Upload</a>
            <DropZone
              class="drop-area"
              @files-dropped="addFiles"
              #default="{ dropZoneActive }"
            >
              <label for="file-input text-center ">
                <span v-if="dropZoneActive">
                  <div class="fs-7 fw-semibold text-gray-400">
                    Drag and drop files here
                  </div>
                </span>
                <span v-else>
                  <div class="fs-7 fw-semibold text-gray-400">
                    Drag and drop files here
                    <span class="smaller">
                      or <strong><em>click here</em></strong> to select files
                    </span>
                  </div>
                </span>

                <input
                  type="file"
                  id="file-input"
                  multiple
                  accept="image/*"
                  capture="user"
                  @change="onInputChange"
                />
              </label>
              <ul class="image-list" v-show="files.length">
                <FilePreview
                  v-for="file of files"
                  :key="file.id"
                  :file="file"
                  tag="li"
                  @remove="removeFile"
                />
              </ul>
            </DropZone>
            <button @click.prevent="uploadFiles(files)" class="upload-button">
              Upload
            </button>
          </div>
          <!--end::Card-->
        
          <!--begin::Tasks-->
          <div class="card card-flush mb-4">
            <!--begin::Card header-->
            <div class="card-header mt-6">
              <!--begin::Card title-->
              <div class="card-title flex-column">
                <h3 class="fw-bold mb-1">My Tasks</h3>
                <div class="fs-6 text-gray-400">Total 25 tasks in backlog</div>
              </div>
              <!--end::Card title-->
              <!--begin::Card toolbar-->
              <div class="card-toolbar">
                <a
                  href="#"
                  class="btn btn-bg-light btn-active-color-primary btn-sm"
                  >View All</a
                >
              </div>
              <!--end::Card toolbar-->
            </div>
            <!--end::Card header-->
            <!--begin::Card body-->
            <div class="card-body d-flex flex-column mb-9 p-9 pt-3">
              <!--begin::Item-->
              <div class="d-flex align-items-center position-relative mb-7">
                <!--begin::Label-->
                <div
                  class="position-absolute top-0 start-0 rounded h-100 bg-secondary w-4px"
                ></div>
                <!--end::Label-->
                <!--begin::Checkbox-->
                <div
                  class="form-check form-check-custom form-check-solid ms-6 me-4"
                >
                  <input class="form-check-input" type="checkbox" value="" />
                </div>
                <!--end::Checkbox-->
                <!--begin::Details-->
                <div class="fw-semibold">
                  <a
                    href="#"
                    class="fs-6 fw-bold text-gray-900 text-hover-primary"
                    >Create FureStibe branding logo</a
                  >
                  <!--begin::Info-->
                  <div class="text-gray-400">
                    Due in 1 day <a href="#">Karina Clark</a>
                  </div>
                  <!--end::Info-->
                </div>
                <!--end::Details-->
                <!--begin::Menu-->
                <button
                  type="button"
                  class="btn btn-clean btn-sm btn-icon btn-icon-primary btn-active-light-primary ms-auto"
                  data-kt-menu-trigger="click"
                  data-kt-menu-placement="bottom-end"
                >
                  <i class="ki-duotone ki-element-plus fs-3">
                    <span class="path1"></span>
                    <span class="path2"></span>
                    <span class="path3"></span>
                    <span class="path4"></span>
                    <span class="path5"></span>
                  </i>
                </button>
                <!--begin::Menu 1-->
                <div
                  class="menu menu-sub menu-sub-dropdown w-250px w-md-300px"
                  data-kt-menu="true"
                  id="kt_menu_64b776109ff83"
                >
                  <!--begin::Header-->
                  <div class="px-7 py-5">
                    <div class="fs-5 text-dark fw-bold">Filter Options</div>
                  </div>
                  <!--end::Header-->
                  <!--begin::Menu separator-->
                  <div class="separator border-gray-200"></div>
                  <!--end::Menu separator-->
                  <!--begin::Form-->
                  <div class="px-7 py-5">
                    <!--begin::Input group-->
                    <div class="mb-10">
                      <!--begin::Label-->
                      <label class="form-label fw-semibold">Status:</label>
                      <!--end::Label-->
                      <!--begin::Input-->
                      <div>
                        <select
                          class="form-select form-select-solid"
                          multiple="multiple"
                          data-kt-select2="true"
                          data-close-on-select="false"
                          data-placeholder="Select option"
                          data-dropdown-parent="#kt_menu_64b776109ff83"
                          data-allow-clear="true"
                        >
                          <option></option>
                          <option value="1">Approved</option>
                          <option value="2">Pending</option>
                          <option value="2">In Process</option>
                          <option value="2">Rejected</option>
                        </select>
                      </div>
                      <!--end::Input-->
                    </div>
                    <!--end::Input group-->
                    <!--begin::Input group-->
                    <div class="mb-10">
                      <!--begin::Label-->
                      <label class="form-label fw-semibold">Member Type:</label>
                      <!--end::Label-->
                      <!--begin::Options-->
                      <div class="d-flex">
                        <!--begin::Options-->
                        <label
                          class="form-check form-check-sm form-check-custom form-check-solid me-5"
                        >
                          <input
                            class="form-check-input"
                            type="checkbox"
                            value="1"
                          />
                          <span class="form-check-label">Author</span>
                        </label>
                        <!--end::Options-->
                        <!--begin::Options-->
                        <label
                          class="form-check form-check-sm form-check-custom form-check-solid"
                        >
                          <input
                            class="form-check-input"
                            type="checkbox"
                            value="2"
                            checked="checked"
                          />
                          <span class="form-check-label">Customer</span>
                        </label>
                        <!--end::Options-->
                      </div>
                      <!--end::Options-->
                    </div>
                    <!--end::Input group-->
                    <!--begin::Input group-->
                    <div class="mb-10">
                      <!--begin::Label-->
                      <label class="form-label fw-semibold"
                        >Notifications:</label
                      >
                      <!--end::Label-->
                      <!--begin::Switch-->
                      <div
                        class="form-check form-switch form-switch-sm form-check-custom form-check-solid"
                      >
                        <input
                          class="form-check-input"
                          type="checkbox"
                          value=""
                          name="notifications"
                          checked="checked"
                        />
                        <label class="form-check-label">Enabled</label>
                      </div>
                      <!--end::Switch-->
                    </div>
                    <!--end::Input group-->
                    <!--begin::Actions-->
                    <div class="d-flex justify-content-end">
                      <button
                        type="reset"
                        class="btn btn-sm btn-light btn-active-light-primary me-2"
                        data-kt-menu-dismiss="true"
                      >
                        Reset
                      </button>
                      <button
                        type="submit"
                        class="btn btn-sm btn-primary"
                        data-kt-menu-dismiss="true"
                      >
                        Apply
                      </button>
                    </div>
                    <!--end::Actions-->
                  </div>
                  <!--end::Form-->
                </div>
                <!--end::Menu 1-->
                <!--end::Menu-->
              </div>
              <!--end::Item-->
              <!--begin::Item-->
              <div class="d-flex align-items-center position-relative mb-7">
                <!--begin::Label-->
                <div
                  class="position-absolute top-0 start-0 rounded h-100 bg-secondary w-4px"
                ></div>
                <!--end::Label-->
                <!--begin::Checkbox-->
                <div
                  class="form-check form-check-custom form-check-solid ms-6 me-4"
                >
                  <input class="form-check-input" type="checkbox" value="" />
                </div>
                <!--end::Checkbox-->
                <!--begin::Details-->
                <div class="fw-semibold">
                  <a
                    href="#"
                    class="fs-6 fw-bold text-gray-900 text-hover-primary"
                    >Schedule a meeting with FireBear CTO John</a
                  >
                  <!--begin::Info-->
                  <div class="text-gray-400">
                    Due in 3 days <a href="#">Rober Doe</a>
                  </div>
                  <!--end::Info-->
                </div>
                <!--end::Details-->
                <!--begin::Menu-->
                <button
                  type="button"
                  class="btn btn-clean btn-sm btn-icon btn-icon-primary btn-active-light-primary ms-auto"
                  data-kt-menu-trigger="click"
                  data-kt-menu-placement="bottom-end"
                >
                  <i class="ki-duotone ki-element-plus fs-3">
                    <span class="path1"></span>
                    <span class="path2"></span>
                    <span class="path3"></span>
                    <span class="path4"></span>
                    <span class="path5"></span>
                  </i>
                </button>
                <!--begin::Menu 1-->
                <div
                  class="menu menu-sub menu-sub-dropdown w-250px w-md-300px"
                  data-kt-menu="true"
                  id="kt_menu_64b776109ff94"
                >
                  <!--begin::Header-->
                  <div class="px-7 py-5">
                    <div class="fs-5 text-dark fw-bold">Filter Options</div>
                  </div>
                  <!--end::Header-->
                  <!--begin::Menu separator-->
                  <div class="separator border-gray-200"></div>
                  <!--end::Menu separator-->
                  <!--begin::Form-->
                  <div class="px-7 py-5">
                    <!--begin::Input group-->
                    <div class="mb-10">
                      <!--begin::Label-->
                      <label class="form-label fw-semibold">Status:</label>
                      <!--end::Label-->
                      <!--begin::Input-->
                      <div>
                        <select
                          class="form-select form-select-solid"
                          multiple="multiple"
                          data-kt-select2="true"
                          data-close-on-select="false"
                          data-placeholder="Select option"
                          data-dropdown-parent="#kt_menu_64b776109ff94"
                          data-allow-clear="true"
                        >
                          <option></option>
                          <option value="1">Approved</option>
                          <option value="2">Pending</option>
                          <option value="2">In Process</option>
                          <option value="2">Rejected</option>
                        </select>
                      </div>
                      <!--end::Input-->
                    </div>
                    <!--end::Input group-->
                    <!--begin::Input group-->
                    <div class="mb-10">
                      <!--begin::Label-->
                      <label class="form-label fw-semibold">Member Type:</label>
                      <!--end::Label-->
                      <!--begin::Options-->
                      <div class="d-flex">
                        <!--begin::Options-->
                        <label
                          class="form-check form-check-sm form-check-custom form-check-solid me-5"
                        >
                          <input
                            class="form-check-input"
                            type="checkbox"
                            value="1"
                          />
                          <span class="form-check-label">Author</span>
                        </label>
                        <!--end::Options-->
                        <!--begin::Options-->
                        <label
                          class="form-check form-check-sm form-check-custom form-check-solid"
                        >
                          <input
                            class="form-check-input"
                            type="checkbox"
                            value="2"
                            checked="checked"
                          />
                          <span class="form-check-label">Customer</span>
                        </label>
                        <!--end::Options-->
                      </div>
                      <!--end::Options-->
                    </div>
                    <!--end::Input group-->
                    <!--begin::Input group-->
                    <div class="mb-10">
                      <!--begin::Label-->
                      <label class="form-label fw-semibold"
                        >Notifications:</label
                      >
                      <!--end::Label-->
                      <!--begin::Switch-->
                      <div
                        class="form-check form-switch form-switch-sm form-check-custom form-check-solid"
                      >
                        <input
                          class="form-check-input"
                          type="checkbox"
                          value=""
                          name="notifications"
                          checked="checked"
                        />
                        <label class="form-check-label">Enabled</label>
                      </div>
                      <!--end::Switch-->
                    </div>
                    <!--end::Input group-->
                    <!--begin::Actions-->
                    <div class="d-flex justify-content-end">
                      <button
                        type="reset"
                        class="btn btn-sm btn-light btn-active-light-primary me-2"
                        data-kt-menu-dismiss="true"
                      >
                        Reset
                      </button>
                      <button
                        type="submit"
                        class="btn btn-sm btn-primary"
                        data-kt-menu-dismiss="true"
                      >
                        Apply
                      </button>
                    </div>
                    <!--end::Actions-->
                  </div>
                  <!--end::Form-->
                </div>
                <!--end::Menu 1-->
                <!--end::Menu-->
              </div>
              <!--end::Item-->
              <!--begin::Item-->
              <div class="d-flex align-items-center position-relative mb-7">
                <!--begin::Label-->
                <div
                  class="position-absolute top-0 start-0 rounded h-100 bg-secondary w-4px"
                ></div>
                <!--end::Label-->
                <!--begin::Checkbox-->
                <div
                  class="form-check form-check-custom form-check-solid ms-6 me-4"
                >
                  <input class="form-check-input" type="checkbox" value="" />
                </div>
                <!--end::Checkbox-->
                <!--begin::Details-->
                <div class="fw-semibold">
                  <a
                    href="#"
                    class="fs-6 fw-bold text-gray-900 text-hover-primary"
                    >9 Degree Porject Estimation</a
                  >
                  <!--begin::Info-->
                  <div class="text-gray-400">
                    Due in 1 week <a href="#">Neil Owen</a>
                  </div>
                  <!--end::Info-->
                </div>
                <!--end::Details-->
                <!--begin::Menu-->
                <button
                  type="button"
                  class="btn btn-clean btn-sm btn-icon btn-icon-primary btn-active-light-primary ms-auto"
                  data-kt-menu-trigger="click"
                  data-kt-menu-placement="bottom-end"
                >
                  <i class="ki-duotone ki-element-plus fs-3">
                    <span class="path1"></span>
                    <span class="path2"></span>
                    <span class="path3"></span>
                    <span class="path4"></span>
                    <span class="path5"></span>
                  </i>
                </button>
                <!--begin::Menu 1-->
                <div
                  class="menu menu-sub menu-sub-dropdown w-250px w-md-300px"
                  data-kt-menu="true"
                  id="kt_menu_64b776109ffa5"
                >
                  <!--begin::Header-->
                  <div class="px-7 py-5">
                    <div class="fs-5 text-dark fw-bold">Filter Options</div>
                  </div>
                  <!--end::Header-->
                  <!--begin::Menu separator-->
                  <div class="separator border-gray-200"></div>
                  <!--end::Menu separator-->
                  <!--begin::Form-->
                  <div class="px-7 py-5">
                    <!--begin::Input group-->
                    <div class="mb-10">
                      <!--begin::Label-->
                      <label class="form-label fw-semibold">Status:</label>
                      <!--end::Label-->
                      <!--begin::Input-->
                      <div>
                        <select
                          class="form-select form-select-solid"
                          multiple="multiple"
                          data-kt-select2="true"
                          data-close-on-select="false"
                          data-placeholder="Select option"
                          data-dropdown-parent="#kt_menu_64b776109ffa5"
                          data-allow-clear="true"
                        >
                          <option></option>
                          <option value="1">Approved</option>
                          <option value="2">Pending</option>
                          <option value="2">In Process</option>
                          <option value="2">Rejected</option>
                        </select>
                      </div>
                      <!--end::Input-->
                    </div>
                    <!--end::Input group-->
                    <!--begin::Input group-->
                    <div class="mb-10">
                      <!--begin::Label-->
                      <label class="form-label fw-semibold">Member Type:</label>
                      <!--end::Label-->
                      <!--begin::Options-->
                      <div class="d-flex">
                        <!--begin::Options-->
                        <label
                          class="form-check form-check-sm form-check-custom form-check-solid me-5"
                        >
                          <input
                            class="form-check-input"
                            type="checkbox"
                            value="1"
                          />
                          <span class="form-check-label">Author</span>
                        </label>
                        <!--end::Options-->
                        <!--begin::Options-->
                        <label
                          class="form-check form-check-sm form-check-custom form-check-solid"
                        >
                          <input
                            class="form-check-input"
                            type="checkbox"
                            value="2"
                            checked="checked"
                          />
                          <span class="form-check-label">Customer</span>
                        </label>
                        <!--end::Options-->
                      </div>
                      <!--end::Options-->
                    </div>
                    <!--end::Input group-->
                    <!--begin::Input group-->
                    <div class="mb-10">
                      <!--begin::Label-->
                      <label class="form-label fw-semibold"
                        >Notifications:</label
                      >
                      <!--end::Label-->
                      <!--begin::Switch-->
                      <div
                        class="form-check form-switch form-switch-sm form-check-custom form-check-solid"
                      >
                        <input
                          class="form-check-input"
                          type="checkbox"
                          value=""
                          name="notifications"
                          checked="checked"
                        />
                        <label class="form-check-label">Enabled</label>
                      </div>
                      <!--end::Switch-->
                    </div>
                    <!--end::Input group-->
                    <!--begin::Actions-->
                    <div class="d-flex justify-content-end">
                      <button
                        type="reset"
                        class="btn btn-sm btn-light btn-active-light-primary me-2"
                        data-kt-menu-dismiss="true"
                      >
                        Reset
                      </button>
                      <button
                        type="submit"
                        class="btn btn-sm btn-primary"
                        data-kt-menu-dismiss="true"
                      >
                        Apply
                      </button>
                    </div>
                    <!--end::Actions-->
                  </div>
                  <!--end::Form-->
                </div>
                <!--end::Menu 1-->
                <!--end::Menu-->
              </div>
              <!--end::Item-->
              <!--begin::Item-->
              <div class="d-flex align-items-center position-relative mb-7">
                <!--begin::Label-->
                <div
                  class="position-absolute top-0 start-0 rounded h-100 bg-secondary w-4px"
                ></div>
                <!--end::Label-->
                <!--begin::Checkbox-->
                <div
                  class="form-check form-check-custom form-check-solid ms-6 me-4"
                >
                  <input class="form-check-input" type="checkbox" value="" />
                </div>
                <!--end::Checkbox-->
                <!--begin::Details-->
                <div class="fw-semibold">
                  <a
                    href="#"
                    class="fs-6 fw-bold text-gray-900 text-hover-primary"
                    >Dashgboard UI & UX for Leafr CRM</a
                  >
                  <!--begin::Info-->
                  <div class="text-gray-400">
                    Due in 1 week <a href="#">Olivia Wild</a>
                  </div>
                  <!--end::Info-->
                </div>
                <!--end::Details-->
                <!--begin::Menu-->
                <button
                  type="button"
                  class="btn btn-clean btn-sm btn-icon btn-icon-primary btn-active-light-primary ms-auto"
                  data-kt-menu-trigger="click"
                  data-kt-menu-placement="bottom-end"
                >
                  <i class="ki-duotone ki-element-plus fs-3">
                    <span class="path1"></span>
                    <span class="path2"></span>
                    <span class="path3"></span>
                    <span class="path4"></span>
                    <span class="path5"></span>
                  </i>
                </button>
                <!--begin::Menu 1-->
                <div
                  class="menu menu-sub menu-sub-dropdown w-250px w-md-300px"
                  data-kt-menu="true"
                  id="kt_menu_64b776109ffb8"
                >
                  <!--begin::Header-->
                  <div class="px-7 py-5">
                    <div class="fs-5 text-dark fw-bold">Filter Options</div>
                  </div>
                  <!--end::Header-->
                  <!--begin::Menu separator-->
                  <div class="separator border-gray-200"></div>
                  <!--end::Menu separator-->
                  <!--begin::Form-->
                  <div class="px-7 py-5">
                    <!--begin::Input group-->
                    <div class="mb-10">
                      <!--begin::Label-->
                      <label class="form-label fw-semibold">Status:</label>
                      <!--end::Label-->
                      <!--begin::Input-->
                      <div>
                        <select
                          class="form-select form-select-solid"
                          multiple="multiple"
                          data-kt-select2="true"
                          data-close-on-select="false"
                          data-placeholder="Select option"
                          data-dropdown-parent="#kt_menu_64b776109ffb8"
                          data-allow-clear="true"
                        >
                          <option></option>
                          <option value="1">Approved</option>
                          <option value="2">Pending</option>
                          <option value="2">In Process</option>
                          <option value="2">Rejected</option>
                        </select>
                      </div>
                      <!--end::Input-->
                    </div>
                    <!--end::Input group-->
                    <!--begin::Input group-->
                    <div class="mb-10">
                      <!--begin::Label-->
                      <label class="form-label fw-semibold">Member Type:</label>
                      <!--end::Label-->
                      <!--begin::Options-->
                      <div class="d-flex">
                        <!--begin::Options-->
                        <label
                          class="form-check form-check-sm form-check-custom form-check-solid me-5"
                        >
                          <input
                            class="form-check-input"
                            type="checkbox"
                            value="1"
                          />
                          <span class="form-check-label">Author</span>
                        </label>
                        <!--end::Options-->
                        <!--begin::Options-->
                        <label
                          class="form-check form-check-sm form-check-custom form-check-solid"
                        >
                          <input
                            class="form-check-input"
                            type="checkbox"
                            value="2"
                            checked="checked"
                          />
                          <span class="form-check-label">Customer</span>
                        </label>
                        <!--end::Options-->
                      </div>
                      <!--end::Options-->
                    </div>
                    <!--end::Input group-->
                    <!--begin::Input group-->
                    <div class="mb-10">
                      <!--begin::Label-->
                      <label class="form-label fw-semibold"
                        >Notifications:</label
                      >
                      <!--end::Label-->
                      <!--begin::Switch-->
                      <div
                        class="form-check form-switch form-switch-sm form-check-custom form-check-solid"
                      >
                        <input
                          class="form-check-input"
                          type="checkbox"
                          value=""
                          name="notifications"
                          checked="checked"
                        />
                        <label class="form-check-label">Enabled</label>
                      </div>
                      <!--end::Switch-->
                    </div>
                    <!--end::Input group-->
                    <!--begin::Actions-->
                    <div class="d-flex justify-content-end">
                      <button
                        type="reset"
                        class="btn btn-sm btn-light btn-active-light-primary me-2"
                        data-kt-menu-dismiss="true"
                      >
                        Reset
                      </button>
                      <button
                        type="submit"
                        class="btn btn-sm btn-primary"
                        data-kt-menu-dismiss="true"
                      >
                        Apply
                      </button>
                    </div>
                    <!--end::Actions-->
                  </div>
                  <!--end::Form-->
                </div>
                <!--end::Menu 1-->
                <!--end::Menu-->
              </div>
              <!--end::Item-->
              <!--begin::Item-->
              <div class="d-flex align-items-center position-relative">
                <!--begin::Label-->
                <div
                  class="position-absolute top-0 start-0 rounded h-100 bg-secondary w-4px"
                ></div>
                <!--end::Label-->
                <!--begin::Checkbox-->
                <div
                  class="form-check form-check-custom form-check-solid ms-6 me-4"
                >
                  <input class="form-check-input" type="checkbox" value="" />
                </div>
                <!--end::Checkbox-->
                <!--begin::Details-->
                <div class="fw-semibold">
                  <a
                    href="#"
                    class="fs-6 fw-bold text-gray-900 text-hover-primary"
                    >Mivy App R&D, Meeting with clients</a
                  >
                  <!--begin::Info-->
                  <div class="text-gray-400">
                    Due in 2 weeks <a href="#">Sean Bean</a>
                  </div>
                  <!--end::Info-->
                </div>
                <!--end::Details-->
                <!--begin::Menu-->
                <button
                  type="button"
                  class="btn btn-clean btn-sm btn-icon btn-icon-primary btn-active-light-primary ms-auto"
                  data-kt-menu-trigger="click"
                  data-kt-menu-placement="bottom-end"
                >
                  <i class="ki-duotone ki-element-plus fs-3">
                    <span class="path1"></span>
                    <span class="path2"></span>
                    <span class="path3"></span>
                    <span class="path4"></span>
                    <span class="path5"></span>
                  </i>
                </button>
                <!--begin::Menu 1-->
                <div
                  class="menu menu-sub menu-sub-dropdown w-250px w-md-300px"
                  data-kt-menu="true"
                  id="kt_menu_64b776109ffc9"
                >
                  <!--begin::Header-->
                  <div class="px-7 py-5">
                    <div class="fs-5 text-dark fw-bold">Filter Options</div>
                  </div>
                  <!--end::Header-->
                  <!--begin::Menu separator-->
                  <div class="separator border-gray-200"></div>
                  <!--end::Menu separator-->
                  <!--begin::Form-->
                  <div class="px-7 py-5">
                    <!--begin::Input group-->
                    <div class="mb-10">
                      <!--begin::Label-->
                      <label class="form-label fw-semibold">Status:</label>
                      <!--end::Label-->
                      <!--begin::Input-->
                      <div>
                        <select
                          class="form-select form-select-solid"
                          multiple="multiple"
                          data-kt-select2="true"
                          data-close-on-select="false"
                          data-placeholder="Select option"
                          data-dropdown-parent="#kt_menu_64b776109ffc9"
                          data-allow-clear="true"
                        >
                          <option></option>
                          <option value="1">Approved</option>
                          <option value="2">Pending</option>
                          <option value="2">In Process</option>
                          <option value="2">Rejected</option>
                        </select>
                      </div>
                      <!--end::Input-->
                    </div>
                    <!--end::Input group-->
                    <!--begin::Input group-->
                    <div class="mb-10">
                      <!--begin::Label-->
                      <label class="form-label fw-semibold">Member Type:</label>
                      <!--end::Label-->
                      <!--begin::Options-->
                      <div class="d-flex">
                        <!--begin::Options-->
                        <label
                          class="form-check form-check-sm form-check-custom form-check-solid me-5"
                        >
                          <input
                            class="form-check-input"
                            type="checkbox"
                            value="1"
                          />
                          <span class="form-check-label">Author</span>
                        </label>
                        <!--end::Options-->
                        <!--begin::Options-->
                        <label
                          class="form-check form-check-sm form-check-custom form-check-solid"
                        >
                          <input
                            class="form-check-input"
                            type="checkbox"
                            value="2"
                            checked="checked"
                          />
                          <span class="form-check-label">Customer</span>
                        </label>
                        <!--end::Options-->
                      </div>
                      <!--end::Options-->
                    </div>
                    <!--end::Input group-->
                    <!--begin::Input group-->
                    <div class="mb-10">
                      <!--begin::Label-->
                      <label class="form-label fw-semibold"
                        >Notifications:</label
                      >
                      <!--end::Label-->
                      <!--begin::Switch-->
                      <div
                        class="form-check form-switch form-switch-sm form-check-custom form-check-solid"
                      >
                        <input
                          class="form-check-input"
                          type="checkbox"
                          value=""
                          name="notifications"
                          checked="checked"
                        />
                        <label class="form-check-label">Enabled</label>
                      </div>
                      <!--end::Switch-->
                    </div>
                    <!--end::Input group-->
                    <!--begin::Actions-->
                    <div class="d-flex justify-content-end">
                      <button
                        type="reset"
                        class="btn btn-sm btn-light btn-active-light-primary me-2"
                        data-kt-menu-dismiss="true"
                      >
                        Reset
                      </button>
                      <button
                        type="submit"
                        class="btn btn-sm btn-primary"
                        data-kt-menu-dismiss="true"
                      >
                        Apply
                      </button>
                    </div>
                    <!--end::Actions-->
                  </div>
                  <!--end::Form-->
                </div>
                <!--end::Menu 1-->
                <!--end::Menu-->
              </div>
              <!--end::Item-->
            </div>
            <!--end::Card body-->
          </div>
          <!--end::Tasks--> 
        
          <div class="card">
            <!--begin::Card head-->
            <div class="card-header card-header-stretch">
              <!--begin::Title-->
              <div class="card-title d-flex align-items-center">
                <i class="ki-duotone ki-calendar-8 fs-1 text-primary me-3 lh-0">
                  <span class="path1"></span>
                  <span class="path2"></span>
                  <span class="path3"></span>
                  <span class="path4"></span>
                  <span class="path5"></span>
                  <span class="path6"></span>
                </i>
                <h3 class="fw-bold m-0 text-gray-800">Activity</h3>
              </div>
              <!--end::Title-->
            </div>
            <!--end::Card head-->
            <!--begin::Card body-->
            <div class="card-body">
              <!--begin::Tab Content-->
              <div class="tab-content">
                <!--begin::Tab panel-->
                <div
                  id="kt_activity_today"
                  class="card-body p-0 tab-pane fade show active"
                  role="tabpanel"
                  aria-labelledby="kt_activity_today_tab"
                >
                  <!--begin::Timeline-->
                  <div class="timeline">
                    <!--begin::Timeline item-->
                    <div
                      class="timeline-item"
                      v-for="action in appointment.activity"
                    >
                      <!--begin::Timeline line-->

                      <!--begin::Timeline item-->
                      <div class="timeline-item">
                        <!--begin::Timeline line-->
                        <div class="timeline-line w-40px"></div>
                        <!--end::Timeline line-->
                        <!--begin::Timeline icon-->
                        <div
                          class="timeline-icon symbol symbol-circle symbol-40px"
                        >
                          <div class="symbol-label bg-light">
                            <i class="ki-duotone ki-basket fs-2 text-gray-500">
                              <span class="path1"></span>
                              <span class="path2"></span>
                              <span class="path3"></span>
                              <span class="path4"></span>
                            </i>
                          </div>
                        </div>
                        <!--end::Timeline icon-->
                        <!--begin::Timeline content-->
                        <div class="timeline-content mt-n1">
                          <!--begin::Timeline heading-->
                          <div class="pe-3 mb-5">
                            <!--begin::Title-->
                            <div class="fs-5 fw-semibold mb-2">
                              {{ action.event }}
                            </div>
                            <!--end::Title-->
                            <!--begin::Description-->
                            <div class="d-flex align-items-center mt-1 fs-6">
                              <!--begin::Info-->
                              <div class="text-muted me-2 fs-7">
                                Placed at
                                {{
                                  moment(action.created_at).format("HH:mm")
                                }}
                                by
                              </div>
                              <!--end::Info-->
                              <!--begin::User-->
                              <a href="#" class="text-primary fw-bold me-1">{{
                                action.event_by
                              }}</a>
                              <!--end::User-->
                            </div>
                            <!--end::Description-->
                          </div>
                          <!--end::Timeline heading-->
                        </div>
                        <!--end::Timeline content-->
                      </div>
                      <!--end::Timeline item-->

                      <!--end::Timeline content-->
                    </div>
                    <!--end::Timeline item-->
                  </div>
                  <!--end::Timeline-->
                </div>
                <!--end::Tab panel-->
              </div>
              <!--end::Tab Content-->
            </div>
            <!--end::Card body-->
          </div>
          <!--end::Timeline-->

      </div>
    </div>
  </div>
</template>

<script setup>
// Components
import DropZone from "@/components/DropZone.vue";
import FilePreview from "@/components/FilePreview.vue";
</script>

<script>
import { mapGetters } from "vuex";
import moment from "moment";
import createUploader from "@/compositions/file-uploader";
import useFileList from "@/compositions/file-list";
const { files, addFiles, removeFile } = useFileList();

export default {
  components: { DropZone, FilePreview },
  data() {
    return {
      currentAppointment: 0,
      appointment: {
        label: "",
        id: "",
        start_at: null,
        end_at: null,
        address: {},
        customer: {
          name: "",
          telephone: "",
          mobile: "", 
        },
        contract: {
          hourly_rate: 0,
        },
        users: [],
      },
    };
  },
  mounted() {
    this.currentAppointment = parseInt(this.$route.params.appointmentId);
    this.appointmentData();
  },
  methods: {
    currency(value) {
      if (typeof value !== "number") {
          return value;
      }
      var formatter = new Intl.NumberFormat('en-GB', {
          style: 'currency',
          currency: 'GBP'
      });
      return formatter.format(value);
    },
    appointmentData() {
      axios
        .get(
          this.apiHost +
            "/api/v1/appointment/" +
            this.currentAppointment +
            "/details"
        )
        .then((response) => {
          this.appointment = response.data.data;
        });
    },
    onInputChange(e) {
      addFiles(e.target.files);
      e.target.value = null; // reset so that selecting the same file again will still cause it to fire this change
    },
    uploadFiles(files) {
      createUploader(
        this.apiHost + "/api/v1/appointment/" + this.appointment.id + "/upload"
      ).uploadFiles(files);
    },
    arriving(appointmentId) {
      axios
        .post(
          this.apiHost + "/api/v1/appointment/" + appointmentId + "/arrive",
          {
            headers: { Authorization: this.getToken },
          }
        )
        .then(() => {
          this.appointmentData();
        })
        .catch((response) => {});
      toastr.success("Arrival Logged", "Date &amp; Time Logged");
    },
    leaving(appointmentId) {
      axios
        .post(
          this.apiHost + "/api/v1/appointment/" + appointmentId + "/leave",
          {
            headers: { Authorization: this.getToken },
          }
        )
        .then(() => {
          this.appointmentData();
        })
        .catch((response) => {});
      toastr.success("Departure Logged", "Date &amp; Time Logged");
    },
  },
  computed: {
    ...mapGetters(["getAppointments"]),
    moment: () => moment,
    googleMapsRouteLink() {
      var string =
        this.appointment.address.address_line1 +
        " " +
        this.appointment.address.postal_code;
      return "https://www.google.com/maps/dir//" + encodeURI(string) + "/"; // double slash after 'dir' + end slash
    },
  },
  watch: {
    appointmentId: {
      handler() {
        this.appointmentData();
      },
    },
  },
};
</script>

<style>
</style>