<template>
  <div class="d-flex align-items-center mb-6">

    <!--begin::Bullet-->
    <span
        data-kt-element="bullet"
        class="bullet bullet-vertical d-flex align-items-center min-h-70px mh-100 me-4 bg-info"
    ></span>
    <!--end::Bullet-->
    <!--begin::Info-->
    <div class="flex-grow-1 me-5">
        <div class="text-gray-800 fw-semibold fs-2">
            <strong>Customer:</strong> {{ appointment.customer.name }} <span v-if="appointment.is_complete">(✅ DONE!)</span>
        </div>
        <div class="text-gray-800 fw-semibold fs-2">
            <strong>Visit Date:</strong> {{ moment(appointment.start_at).format("ddd Do MMM ") }}
        </div>
        <div class="text-gray-800 fw-semibold fs-2">
            <strong>Contract Details: </strong> <span class="fs-4">{{ appointment.display_contract_period }}</span>
        </div>
        
        <!--begin::Link-->
        <div class="fs-5 mt-5">
        <strong>Visit Specific Notes:</strong>
        <!--begin::Name-->
        <span
            class="text-gray-700 fw-semibold fs-7"
            v-html="appointment.specific_notes"
        ></span>
        <!--end::Name-->
        </div>
        <div class="fs-5 mt-5">
        <strong>Visit Specific Tasks:</strong>
        <ul>
            <li
            v-for="service in appointment.appointment_specific_services"
            class="fs-7">
            - {{ service.label }}
            </li>
        </ul>
        </div>
        <div class="fs-7 my-3 text-gray-700">
        <strong>Address: </strong>
            <span
            v-if="appointment.address"
            >
            {{ appointment.address.address_line1 ?? "" }},
            {{ appointment.address.address_line2 ?? "" }},
            {{ appointment.address.postal_code ?? "" }}
            </span>
            <span v-else>
                No address available.
            </span>
        </div>
        <div class="fs-7 my-3 text-gray-700">
        <strong>Guide arrival time:</strong>
        <span class="pl-3 text-blue-700 fw-semibold">{{
            moment(appointment.start_at).format("h:mmA")
        }}</span>
        </div>
        <!--end::Link-->
    </div>
    <!--end::Info-->
    <!--begin::Action-->
    <div v-if="appointment.id">
        <router-link
        :to="{
            name: 'appointment',
            params: { appointmentId: appointment.id },
        }"
        class="btn btn-sm btn-light"
        >View</router-link
        >
    </div>
    <!--end::Action-->
  </div>
</template>

<script>
import moment from "moment";
export default {
    props: {
        appointment: {
            type: Object,
        }
    },
    computed: {
        moment: () => moment,
    },
}
</script>

<style>

</style>