<template>
  <div>
    <div
      id="kt_app_header"
      class="app-header"
      data-kt-sticky="true"
      data-kt-sticky-activate="{default: true, lg: true}"
      data-kt-sticky-name="app-header-minimize"
      data-kt-sticky-offset="{default: '200px', lg: '0'}"
      data-kt-sticky-animation="false"
    >
      <div
        class="app-container container-fluid d-flex align-items-stretch justify-content-between"
        id="kt_app_header_container"
      >
        <div
          class="d-flex align-items-center d-lg-none ms-n3 me-1 me-md-2"
          title="Show sidebar menu"
        >
          <div
            class="btn btn-icon btn-active-color-primary w-35px h-35px"
            id="kt_app_sidebar_mobile_toggle"
          >
            <i class="ki-duotone ki-abstract-14 fs-2 fs-md-1">
              <span class="path1"></span>
              <span class="path2"></span>
            </i>
          </div>
        </div>

        <div class="d-flex align-items-center flex-grow-1 flex-lg-grow-0">
          <a href="/" class="d-lg-none">
            <img
              alt="Logo"
              src="https://greenart.co.uk/wp-content/uploads/2017/06/greenart-logo.png"
              class="h-30px"
            />
          </a>
        </div>

        <div
          class="d-flex align-items-stretch justify-content-between flex-lg-grow-1"
          id="kt_app_header_wrapper"
        >
          <div class="app-navbar flex-shrink-0">
            
            <div
              class="app-navbar-item ms-1 ms-md-4"
              id="kt_header_user_menu_toggle"
            >
              <div
                class="cursor-pointer symbol symbol-35px"
                data-kt-menu-trigger="{default: 'click', lg: 'hover'}"
                data-kt-menu-attach="parent"
                data-kt-menu-placement="bottom-end"
              >
                <img :src="getUserAvatar" class="rounded-3" alt="user" />
              </div>

              <div
                class="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-800 menu-state-bg menu-state-color fw-semibold py-4 fs-6 w-275px"
                data-kt-menu="true"
              >
                <div class="menu-item px-3">
                  <div class="menu-content d-flex align-items-center px-3">
                    <div class="symbol symbol-50px me-5">
                      <img alt="Logo" :src="getUserAvatar" />
                    </div>

                    <div class="d-flex flex-column">
                      <div class="fw-bold d-flex align-items-center fs-5">
                        {{ getUserName }}
                      </div>
                    
                    </div>
                  </div>
                </div>

                <div class="separator my-2"></div>

                <div class="menu-item px-5">
                  <a @click.prevent="signout" class="menu-link px-5"
                    >Sign Out</a
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  methods: {
    ...mapActions(["logout"]),
    signout() {
      this.logout();
      this.$router.push("login");
    },
  },
  computed: {
    ...mapGetters(["getUser"]),
    getUserAvatar() {
      return this.getUser.avatar;
    },
    getUserName() {
      return this.getUser.first_name + " " + this.getUser.last_name;
    }
  },
};
</script>

<style>
</style>