import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from 'axios'
import config from '@/config'
import './index.css'

let APP_API_HOST = process.env.VUE_APP_API_HOST
let APP_PATH_PREFIX = process.env.VUE_APP_PATH_PREFIX

window._ = require('lodash');

const app = createApp(App)
    
app.config.globalProperties.apiHost = APP_API_HOST; 
app.config.globalProperties.assetPrefix = APP_PATH_PREFIX; 
axios.defaults.baseURL = APP_API_HOST;
axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';

app.use(store).use(router).mount('#app') 