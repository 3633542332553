const state = {
    wrapper: {
        isApp: true,
    },
}
const getters = {
    getWrapperIsApp: state => state.wrapper.isApp,
}
const actions = {
    setIsWrapperApp({
        context,
        state,
        commit
    }, newIsAppWrapper) {
        commit('setIsWrapperApp', newIsAppWrapper)
    },
}
const mutations = {
    setIsWrapperApp(state, newIsAppWrapper) {
        state.wrapper.isApp = newIsAppWrapper
    }
}

export default {
    state,
    getters,
    actions,
    mutations
}