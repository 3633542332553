<template>
  <div>
    <!--begin::Root-->
    <div class="d-flex flex-column flex-root" id="kt_app_root">
      <!--begin::Authentication - Sign-in -->
      <div class="d-flex flex-column flex-lg-row flex-column-fluid">
        <!--begin::Body-->
        <div
          class="d-flex flex-column flex-lg-row-fluid w-lg-50 p-10 order-2 order-lg-1"
        >
          <!--begin::Form-->
          <div class="d-flex flex-center flex-column flex-lg-row-fluid">
            <!--begin::Wrapper-->
            <div class="w-lg-500px p-10">
              <!--begin::Form-->
              <form class="form w-100" id="kt_sign_in_form">
                <!--begin::Heading-->
                <div class="text-center mb-11">
                  <!--begin::Title-->
                  <h1 class="text-dark fw-bolder mb-3">Sign In</h1>
                  <!--end::Title-->
                </div>
                <!--begin::Heading-->

                <!--end::Separator-->
                <!--begin::Input group=-->
                <div class="fv-row mb-8">
                  <!--begin::Email-->
                  <input
                    type="text"
                    placeholder="Email"
                    name="email"
                    autocomplete="off"
                    class="form-control bg-transparent"
                    v-model="email"
                  />
                  <div
                    class="fv-plugins-message-container fv-plugins-message-container--enabled invalid-feedback"
                    v-if="errors['email'] && errors.email.length > 0"
                  >
                    <div v-for="error in errors.email">{{ error }}</div>
                  </div>
                  <!--end::Email-->
                </div>
                <!--end::Input group=-->
                <div class="fv-row mb-3">
                  <!--begin::Password-->
                  <input
                    type="password"
                    placeholder="Password"
                    name="password"
                    autocomplete="off"
                    class="form-control bg-transparent"
                    v-model="password"
                  />
                  <div
                    class="fv-plugins-message-container fv-plugins-message-container--enabled invalid-feedback"
                    v-if="errors['password'] && errors.password.length > 0"
                  >
                    <div v-for="error in errors.password">{{ error }}</div>
                  </div>
                  <!--end::Password-->
                </div>
                <div
                  class="fv-plugins-message-container fv-plugins-message-container--enabled invalid-feedback"
                  v-if="errors['message']"
                >
                  <div>{{ errors["message"] }}</div>
                </div>
                <!--end::Input group=-->
                <!--begin::Wrapper-->
                <div
                  class="d-flex flex-stack flex-wrap gap-3 fs-base fw-semibold mb-8"
                >
                  <div></div>
                  <!--begin::Link-->
                  <a href="mailto:support@garbott.co.uk" class="link-primary"
                    >Forgot Password ?</a
                  >
                  <!--end::Link-->
                </div>
                <!--end::Wrapper-->
                <!--begin::Submit button-->
                <div class="d-grid mb-10">
                  <button
                    type="submit"
                    id="kt_sign_in_submit"
                    class="btn btn-primary"
                    @click.prevent="submitForm"
                  >
                    <!--begin::Indicator label-->
                    <span class="indicator-label">Sign In</span>
                    <!--end::Indicator label-->
                  </button>
                </div>
                <!--end::Submit button-->
              </form>
              <!--end::Form-->
            </div>
            <!--end::Wrapper-->
          </div>
          <!--end::Form-->
          <!--begin::Footer-->
          <div class="w-lg-500px d-flex flex-stack px-10 mx-auto">
                      
          </div>
          <!--end::Footer-->
        </div>
        <!--end::Body-->
        <!--begin::Aside-->
        <div
          class="d-flex flex-lg-row-fluid w-lg-50 bgi-size-cover bgi-position-center order-1 order-lg-2"
          :style="{'background-image': 'url('+assetPrefix+'/assets/media/misc/auth-bg.png)'}"
        >
          <!--begin::Content-->
          <div
            class="d-flex flex-column flex-center py-7 py-lg-15 px-5 px-md-15 w-100"
          >
            <!--begin::Logo-->
            <a href="/ " class="mb-0 mb-lg-12">
              <img
                alt="Logo"
                :src="assetPrefix+logo"
                class="h-60px h-lg-75px"
              />
            </a>
            <!--end::Logo-->
            <!--begin::Image-->
            <img
              class="d-none d-lg-block mx-auto w-275px w-md-50 w-xl-500px mb-10 mb-lg-20"
              src="https://greenart.co.uk/wp-content/uploads/2023/09/GardenCareandDevelopment_WebsiteSquare.jpg"
              alt=""
            />
            <!--end::Image-->
            <!--begin::Title-->
            <h1
              class="d-none d-lg-block text-white fs-2qx fw-bolder text-center mb-7"
            >
              Fast, Efficient and Productive
            </h1>
            <!--end::Title-->
            <!--begin::Text-->
            <div class="d-none d-lg-block text-white fs-base text-center">
              An easy-to-use web application for GreenArt services.
            </div>
            <!--end::Text-->
          </div>
          <!--end::Content-->
        </div>
        <!--end::Aside-->
      </div>
      <!--end::Authentication - Sign-in-->
    </div>
    <!--end::Root-->
  </div>
</template>

<script>
import axios from "axios";
import Logo from '@/assets/logo.svg'
import { mapGetters, mapActions } from "vuex";
export default {
  data() {
    return {
      logo: Logo,
      email: "",
      password: "",

      errors: {
        email: [],
        password: [],
      },
    };
  },
  mounted() {
    this.setIsLoading(false);
    this.setIsWrapperApp(false);
  },
  methods: {
    ...mapActions([
      "setIsLoading",
      "setIsLoggedIn",
      "setToken",
      "setIsWrapperApp",
    ]),
    submitForm() {
      this.setIsLoading(true);
      axios
        .post(this.apiHost + "/api/v1/auth/login", {
          email: this.email,
          password: this.password,
        })
        .then((response) => {
          this.setToken(response.data.data.token);
          this.setIsLoggedIn(true);
		  this.setIsWrapperApp(true);
          this.$router.push("home");
        })
        .catch((error) => {
          if (error.response.data.data.errors) {
            this.errors = error.response.data.data.errors;
          } else {
            this.errors = error.response.data.data;
          }
        })
        .finally(() => this.setIsLoading(false));
    },
  },
  computed: {
    ...mapGetters(["getWrapperIsApp"]),
  },
};
</script>

<style>
</style>