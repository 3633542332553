<template>
  <div id="kt_app_toolbar" class="app-toolbar py-3 py-lg-6">
    <div
      id="kt_app_toolbar_container"
      class="app-container container-xxl d-flex flex-stack"
    >
      <div
        class="page-title d-flex flex-column justify-content-center flex-wrap me-3"
      >
        <h1
          class="page-heading d-flex text-dark fw-bold fs-3 flex-column justify-content-center my-0"
        >
          Our Visits
        </h1>

        <ul
          class="breadcrumb breadcrumb-separatorless fw-semibold fs-7 my-0 pt-1"
        >
          <li class="breadcrumb-item text-muted">
            <router-link
              to="/"
              class="text-muted text-hover-primary"
              >Home</router-link>
          </li>

          <li class="breadcrumb-item">
            <span class="bullet bg-gray-400 w-5px h-2px"></span>
          </li>

          <li class="breadcrumb-item text-muted">Dashboards</li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style>
</style>