<template>
  <div
    id="kt_app_sidebar"
    class="app-sidebar flex-column"
    data-kt-drawer="true"
    data-kt-drawer-name="app-sidebar"
    data-kt-drawer-activate="{default: true, lg: false}"
    data-kt-drawer-overlay="true"
    data-kt-drawer-width="225px"
    data-kt-drawer-direction="start"
    data-kt-drawer-toggle="#kt_app_sidebar_mobile_toggle"
  >
    <div class="app-sidebar-logo px-6 bg-white" id="kt_app_sidebar_logo">
      <router-link class="menu-link" to="/">
        <img
          alt="Logo"
          src="/assets/greenart/logo.png"
          class=" app-sidebar-logo-default"
        />
        <img
          alt="Logo"
          src="/assets/greenart/logo.png"
          class=" app-sidebar-logo-minimize"
        />
      </router-link>
      <div
        id="kt_app_sidebar_toggle"
        class="app-sidebar-toggle btn btn-icon btn-shadow btn-sm btn-color-muted btn-active-color-primary h-30px w-30px position-absolute top-50 start-100 translate-middle rotate"
        data-kt-toggle="true"
        data-kt-toggle-state="active"
        data-kt-toggle-target="body"
        data-kt-toggle-name="app-sidebar-minimize"
      >
        <i class="ki-duotone ki-black-left-line fs-3 rotate-180">
          <span class="path1"></span>
          <span class="path2"></span>
        </i>
      </div>
    </div>

    <div class="app-sidebar-menu overflow-hidden flex-column-fluid">
      <div id="kt_app_sidebar_menu_wrapper" class="app-sidebar-wrapper">
        <div
          id="kt_app_sidebar_menu_scroll"
          class="scroll-y my-5 mx-3"
          data-kt-scroll="true"
          data-kt-scroll-activate="true"
          data-kt-scroll-height="auto"
          data-kt-scroll-dependencies="#kt_app_sidebar_logo, #kt_app_sidebar_footer"
          data-kt-scroll-wrappers="#kt_app_sidebar_menu"
          data-kt-scroll-offset="5px"
          data-kt-scroll-save-state="true"
        >
          <div
            class="menu menu-column menu-rounded menu-sub-indention fw-semibold fs-6"
            id="#kt_app_sidebar_menu"
            data-kt-menu="true"
            data-kt-menu-expand="false"
          >
            <div
              data-kt-menu-trigger="click"
              class="menu-item here show menu-accordion"
            >
              <span class="menu-link">
                <span class="menu-icon">
                  <i class="ki-duotone ki-element-11 fs-2">
                    <span class="path1"></span>
                    <span class="path2"></span>
                    <span class="path3"></span>
                    <span class="path4"></span>
                  </i>
                </span>
                <span class="menu-title">Dashboards</span>
                <span class="menu-arrow"></span>
              </span>

              <div class="menu-sub menu-sub-accordion">
                <div class="menu-item">
                  <router-link class="menu-link" to="/">
                    <span class="menu-bullet">
                      <span class="bullet bullet-dot"></span>
                    </span>
                    <span class="menu-title">Homepage</span>
                  </router-link>
                  <!-- <a href="https://www.whatsmybrowser.org/">
                    <span class="menu-bullet">
                      <span class="bullet bullet-dot"></span>
                    </span>
                    <span class="menu-title">Whats my browser</span>
                  </a> -->
                </div>               
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- <div
      class="app-sidebar-footer flex-column-auto pt-2 pb-6 px-6"
      id="kt_app_sidebar_footer"
    >
      <a
        href="https://preview.keenthemes.com/html/metronic/docs"
        class="btn btn-flex flex-center btn-custom btn-primary overflow-hidden text-nowrap px-0 h-40px w-100"
        data-bs-toggle="tooltip"
        data-bs-trigger="hover"
        data-bs-dismiss-="click"
        title="200+ in-house components and 3rd-party plugins"
      >
        <span class="btn-label">Docs & Components</span>
        <i class="ki-duotone ki-document btn-icon fs-2 m-0">
          <span class="path1"></span>
          <span class="path2"></span>
        </i>
      </a>
    </div> -->
  </div>
</template>

<script>
export default {};
</script>

<style>
</style>