const state = {
    isLoggedIn: false,
    token: '',
    appointments: [],
    appointments_week: [],
    user: {
        first_name: '',
        last_name: '',
        avatar: '',
    },
}
const getters = {
    isLoggedIn: state => state.isLoggedIn,
    getToken: state => state.token,
    getAppointments: state => state.appointments,
    getAppointmentsForWeek: state => state.appointments_week,
    getUser: state => state.user,
}
const actions = {
    setUserFirstName({ context, state, commit }, newUserFirstName) {
        commit('setUserFirstName', newUserFirstName)
    },
    setUserLastName({ context, state, commit }, newUserLastName) {
        commit('setUserLastName', newUserLastName)
    },
    setUserAvatar({ context, state, commit }, newUserAvatar) {
        commit('setUserAvatar', newUserAvatar)
    },
    setIsLoggedIn({ context, state, commit }, newLoggedInState) {
        commit('setIsLoggedIn', newLoggedInState)
    },
    setToken({ context, state, commit }, newToken) {
        commit('setToken', newToken)
    },
    setAppointments({ context, state, commit }, newAppointments) {
        commit('setAppointments', newAppointments)
    },
    setAppointmentsForWeek({ context, state, commit }, newAppointments) {
        commit('setAppointmentsForWeek', newAppointments)
    },
    logout({ context, state, commit }) {
        commit('logout')
    }
}
const mutations = {
    setUserFirstName(state, newUserFirstName) {
        state.user.first_name = newUserFirstName
    },
    setUserLastName(state, newUserLastName) {
        state.user.last_name = newUserLastName
    },
    setUserAvatar(state, newUserAvatar) {
        state.user.avatar = newUserAvatar
    },
    setIsLoggedIn(state, newLoggedInState) {
        state.isLoggedIn = newLoggedInState
    },
    setToken(state, newToken) {
        state.token = newToken
    },
    setAppointments(state, newAppointments) {
        state.appointments = newAppointments
    },
    setAppointmentsForWeek(state, newAppointments) {
        state.appointments_week = newAppointments
    },
    logout(state) {
        state.isLoggedIn = false;
        state.token = '';
    }
}

export default {
    state,
    getters,
    actions,
    mutations,
}